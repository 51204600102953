<div
  class="round-icon-marker-wrapper clickable"
  [class.light-mode]="isLightMode"
  [class.status-invalid]="statusInvalid"
  [ngStyle]="{
    '--icon-color': color,
    '--heading-offset': headingOffset,
    '--direction-angle-deg': directionAngleDeg,
  }"
>
  <div class="shadow"></div>

  <div class="marker-icon">
    <app-transport-icon-selector
      [color]="'var(--color-white)'"
      [icon]="icon"
    ></app-transport-icon-selector>
  </div>

  <div class="arrow" *ngIf="status === VehicleStatus.Running">
    <svg width="34" height="34" viewBox="0 0 34 34" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clip-path="url(#clip0_170_6818)">
        <g filter="url(#filter0_d_170_6818)">
          <path
            d="M8.43911 10.7651C9.07736 10.2601 9.98432 10.2392 10.6449 10.714L17.0378 15.3088L24.3164 12.3083C25.0686 11.9982 25.9464 12.2273 26.4511 12.8653C26.9629 13.5122 26.9893 14.4009 26.5168 15.0769L15.7956 30.4157C15.735 30.5024 15.652 30.571 15.5555 30.6144C15.459 30.6577 15.3525 30.6742 15.2475 30.6619C15.1424 30.6497 15.0426 30.6092 14.9586 30.5448C14.8747 30.4804 14.8098 30.3945 14.7707 30.2961L7.86651 12.9019C7.56227 12.1355 7.79238 11.2767 8.43911 10.7651Z"
            fill="var(--arrow-color)"
            style="fill-opacity: 1"
          />
          <path
            d="M10.9368 10.308L10.6449 10.714L10.9367 10.308C10.0948 9.7029 8.94218 9.72952 8.12889 10.3729C7.30899 11.0216 7.01607 12.1147 7.40178 13.0864L14.306 30.4806L14.306 30.4807C14.3784 30.6629 14.4987 30.8221 14.6543 30.9415C14.8098 31.0608 14.9948 31.1358 15.1895 31.1585C15.3843 31.1813 15.5815 31.1508 15.7604 31.0705C15.9392 30.9901 16.093 30.8629 16.2053 30.7022L16.2054 30.7021L26.9267 15.3633L26.9267 15.3633C27.5256 14.5063 27.492 13.3751 26.8432 12.555C26.2 11.742 25.0844 11.4509 24.1258 11.846L24.1258 11.846L17.1041 14.7406L10.9368 10.308Z"
            stroke="white"
            style="stroke: white; stroke-opacity: 1"
          />
        </g>
      </g>
      <defs>
        <filter
          id="filter0_d_170_6818"
          x="-49.2656"
          y="-46.6284"
          width="133.119"
          height="134.294"
          filterUnits="userSpaceOnUse"
          color-interpolation-filters="sRGB"
        >
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feMorphology
            radius="4"
            operator="erode"
            in="SourceAlpha"
            result="effect1_dropShadow_170_6818"
          />
          <feOffset />
          <feGaussianBlur stdDeviation="30" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.0627451 0 0 0 0 0.0941176 0 0 0 0 0.156863 0 0 0 0.08 0"
          />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_170_6818" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_170_6818"
            result="shape"
          />
        </filter>
      </defs>
    </svg>
  </div>

  <div class="status-icon" *ngIf="status !== VehicleStatus.Running">
    <svg
      *ngIf="status === VehicleStatus.Stopped"
      class="icon-stopped"
      width="20"
      height="20"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        x="1"
        y="1"
        width="22"
        height="22"
        rx="11"
        fill="#667085"
        style="fill: #667085; fill: color(display-p3 0.4 0.4392 0.5216); fill-opacity: 1"
      />
      <rect
        x="1"
        y="1"
        width="22"
        height="22"
        rx="11"
        stroke="#F4EBFF"
        style="stroke: #f4ebff; stroke: color(display-p3 0.9569 0.9216 1); stroke-opacity: 1"
        stroke-width="2"
      />
      <g clip-path="url(#clip0_170_6792)">
        <rect
          x="8.66699"
          y="8.66663"
          width="6.66667"
          height="6.66667"
          fill="white"
          style="fill: white; fill-opacity: 1"
        />
      </g>
    </svg>

    <svg
      *ngIf="status === VehicleStatus.Idling"
      class="icon-idling"
      width="20"
      height="20"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        x="1"
        y="1"
        width="22"
        height="22"
        rx="11"
        fill="var(--icon-color)"
        style="fill-opacity: 1"
      />
      <rect
        x="1"
        y="1"
        width="22"
        height="22"
        rx="11"
        stroke="var(--border-color)"
        style="stroke-opacity: 1"
        stroke-width="2"
      />
      <path
        d="M8.5 12H15.5"
        stroke="white"
        style="stroke: white; stroke-opacity: 1"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  </div>

  <div class="heading text-12 fw-600">
    {{ name }}
  </div>
</div>
