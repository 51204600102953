<div class="filter-bar-wrapper">
  <div class="date">
    @if (showDatePicker) {
      <app-date-range-picker-dialog
        [resetByToday]="true"
        [btnClass]="'p--16'"
        [dateRange]="dateRange ?? undefined"
        (dateRangeChange)="dateRangeChanged.emit($event)"
        class="controls-button"
      ></app-date-range-picker-dialog>
    }
  </div>

  @if (showFilters) {
    <p-button
      class="flat text-14 fw-600 h--40 p--12"
      badge="{{ filtersCount || undefined }}"
      icon="{{ filterButtonIconOnly && !filtersCount ? 'pi' : '' }}"
      [severity]="'primary'"
      [outlined]="true"
      (click)="filterPanelService.openPanel()"
    >
      @if (
        defaultSortOrder?.order !== gridSortOrder?.order ||
        defaultSortOrder?.column !== gridSortOrder?.column
      ) {
        <app-icon-filters-active
          [height]="20"
          [width]="20"
          class="filters icon"
        ></app-icon-filters-active>
      } @else {
        <app-icon-filters [height]="20" [width]="20" class="filters icon"></app-icon-filters>
      }

      @if (!filterButtonIconOnly) {
        {{ 'button.filters' | translate }}
      }
    </p-button>
  }
</div>
