import {
  Component,
  EventEmitter,
  inject,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
} from '@angular/core'
import { DateRange, PreDefinedRange } from '@ti-platform/web/common'
import { FilterPanelService } from '../filter-panel/filter-panel.service'
import { DeviceService } from '@ti-platform/web/ui-kit/i18n'
import { DataGridSortOrder } from '@ti-platform/web/ui-kit/layout'

@Component({
  selector: 'app-filter-bar',
  templateUrl: 'filter-bar.component.html',
  styleUrls: ['filter-bar.component.scss'],
})
export class FilterBarComponent implements OnChanges {
  @Output() dateRangeChanged = new EventEmitter<DateRange | PreDefinedRange | undefined>()

  @Input() dateRange?: DateRange | PreDefinedRange | null
  @Input() showDatePicker = true
  @Input() showFilters = true
  @Input() filtersCount?: number

  @Input() gridSortOrder?: DataGridSortOrder | null
  @Input() defaultSortOrder?: DataGridSortOrder | null

  protected filterButtonIconOnly?: boolean

  protected readonly deviceService = inject(DeviceService)
  protected readonly filterPanelService = inject(FilterPanelService)

  public ngOnChanges(changes: SimpleChanges) {
    if (changes.dateRange) {
      const range = changes.dateRange.currentValue
      this.filterButtonIconOnly = Array.isArray(range) && range[0] && range[1]
    }
  }
}
