import { ComponentRef } from '@angular/core'
import { hexToRgb } from '@ti-platform/web/common'
import { Map as MaplibreGlMap, Marker } from 'maplibre-gl'
import { AlertMakerComponent } from '../components'
import { ComponentFactory, Coordinates } from '../contracts'
import { getPolylineDirection, toLngLat } from '../utils'
import { BaseMarker } from './base'

export interface AlertMarkerOptions {
  alertId: string
  primaryPointLatLng: Coordinates
  secondaryPointLatLng?: Coordinates
  route?: Coordinates[]
  routeBefore?: Coordinates[]
  routeAfter?: Coordinates[]
}

export abstract class AbstractAlertMarker extends BaseMarker {
  public override readonly options!: AlertMarkerOptions

  public getFullRoute() {
    return [
      ...(this.options.routeBefore ?? []),
      ...(this.options.route ?? [this.options.primaryPointLatLng]),
      ...(this.options.routeAfter ?? []),
    ]
  }
}

export class MaplibreAlertMarker extends AbstractAlertMarker {
  protected readonly color = '#C3505D'
  protected readonly layerID!: string
  protected readonly sourceID!: string

  // Smooth polyline for route rendering
  protected readonly route: Coordinates[]
  protected readonly routeBefore: Coordinates[]
  protected readonly routeAfter: Coordinates[]

  // The direction of primary marker arrow
  protected readonly directionAngle?: number

  protected primaryPointMarker!: Marker
  protected secondaryPointMarker?: Marker

  protected primaryPointCmpRef!: ComponentRef<AlertMakerComponent>
  protected secondaryPointCmpRef?: ComponentRef<AlertMakerComponent>

  public constructor(
    public override readonly options: AlertMarkerOptions,
    protected override readonly mapRef: MaplibreGlMap,
    protected override readonly componentFactory: ComponentFactory<AlertMakerComponent>,
  ) {
    super(options, mapRef, componentFactory)

    this.layerID = `layer-alert-${options.alertId}`
    this.sourceID = `source-alert-${options.alertId}`

    this.route = this.prepareRoute(this.options.route)
    this.routeAfter = this.prepareRoute(this.options.routeAfter)
    this.routeBefore = this.prepareRoute(this.options.routeBefore)

    const fullRoute = this.getFullRoute()
    if (fullRoute.length > 1) {
      this.directionAngle = this.calculateDirectionAngle(
        this.getFullRoute(),
        this.options.primaryPointLatLng,
      )
    }

    this.primaryPointMarker = new Marker({
      element: document.createElement('div'),
      rotationAlignment: 'map',
    }).setLngLat(toLngLat(this.options.primaryPointLatLng))

    if (this.options.secondaryPointLatLng) {
      this.secondaryPointMarker = new Marker({
        element: document.createElement('div'),
      }).setLngLat(toLngLat(this.options.secondaryPointLatLng))
    }

    this.init()
  }

  protected init() {
    this.initSource()
    this.initLayers()
    this.renderComponents()

    // Reinitialize the layers after style data is updated
    const repairAfterStyleRefresh = () => {
      this.initSource()
      this.initLayers()
    }
    this.mapRef.on('styledata', repairAfterStyleRefresh)
    this.destroy$.subscribe(() => this.mapRef.off('styledata', repairAfterStyleRefresh))
  }

  protected initSource() {
    if (this.routeBefore.length && !this.mapRef.getSource(`${this.sourceID}.0`)) {
      const route = [...this.routeBefore, this.route[0] ?? this.options.primaryPointLatLng]
      this.mapRef.addSource(`${this.sourceID}.0`, {
        type: 'geojson',
        lineMetrics: true,
        data: {
          id: 'route-line',
          type: 'Feature',
          properties: {},
          geometry: {
            type: 'LineString',
            coordinates: route.map((item) => toLngLat(item)),
          },
        },
      })
    }

    if (this.route?.length && !this.mapRef.getSource(`${this.sourceID}.1`)) {
      this.mapRef.addSource(`${this.sourceID}.1`, {
        type: 'geojson',
        data: {
          id: 'route-line',
          type: 'Feature',
          properties: {},
          geometry: {
            type: 'LineString',
            coordinates: this.route.map((item) => toLngLat(item)),
          },
        },
      })
    }

    if (this.routeAfter?.length && !this.mapRef.getSource(`${this.sourceID}.2`)) {
      const route = [
        this.route[this.route.length - 1] ??
          this.options.secondaryPointLatLng ??
          this.options.primaryPointLatLng,
        ...this.routeAfter,
      ]
      this.mapRef.addSource(`${this.sourceID}.2`, {
        type: 'geojson',
        lineMetrics: true,
        data: {
          id: 'route-line',
          type: 'Feature',
          properties: {},
          geometry: {
            type: 'LineString',
            coordinates: route.map((item) => toLngLat(item)),
          },
        },
      })
    }
  }

  protected initLayers() {
    if (this.routeBefore?.length && !this.mapRef.getLayer(`${this.layerID}.0`)) {
      this.mapRef.addLayer({
        id: `${this.layerID}.0`,
        type: 'line',
        source: `${this.sourceID}.0`,
        layout: {
          'line-join': 'round',
          'line-cap': 'round',
        },
        paint: {
          'line-width': 4,
          'line-gradient': [
            'interpolate',
            ['linear'],
            ['line-progress'],
            0,
            `rgba(${hexToRgb(this.color).join(',')}, 0)`,
            1,
            this.color,
          ],
        },
      })
    }
    if (this.route?.length && !this.mapRef.getLayer(`${this.layerID}.1`)) {
      this.mapRef.addLayer({
        id: `${this.layerID}.1`,
        type: 'line',
        source: `${this.sourceID}.1`,
        layout: {
          'line-join': 'round',
          'line-cap': 'round',
        },
        paint: {
          'line-width': 4,
          'line-color': this.color,
        },
      })
    }
    if (this.routeAfter?.length && !this.mapRef.getLayer(`${this.layerID}.2`)) {
      this.mapRef.addLayer({
        id: `${this.layerID}.2`,
        type: 'line',
        source: `${this.sourceID}.2`,
        layout: {
          'line-join': 'round',
          'line-cap': 'round',
        },
        paint: {
          'line-width': 4,
          'line-gradient': [
            'interpolate',
            ['linear'],
            ['line-progress'],
            0,
            this.color,
            1,
            `rgba(${hexToRgb(this.color).join(',')}, 0)`,
          ],
        },
      })
    }
  }

  protected renderComponents() {
    if (!this.primaryPointCmpRef) {
      this.componentFactory(AlertMakerComponent, {
        type: 'primary',
        color: this.color,
        showArrow: this.directionAngle !== undefined,
        arrowDirection: this.directionAngle,
      }).then((ref) => {
        this.primaryPointMarker.getElement().appendChild(ref.location.nativeElement)
        this.primaryPointMarker.addTo(this.mapRef)
        this.primaryPointCmpRef = ref
      })
    }

    if (this.secondaryPointMarker && !this.secondaryPointCmpRef) {
      this.componentFactory(AlertMakerComponent, { type: 'secondary', color: this.color }).then(
        (ref) => {
          this.secondaryPointMarker?.getElement().appendChild(ref.location.nativeElement)
          this.secondaryPointMarker?.addTo(this.mapRef)
          this.secondaryPointCmpRef = ref
        },
      )
    }
  }

  public override destroy() {
    super.destroy()

    this.primaryPointMarker.remove()
    this.primaryPointCmpRef.destroy()
    this.secondaryPointMarker?.remove()
    this.secondaryPointCmpRef?.destroy()

    const layerIds = [`${this.layerID}.0`, `${this.layerID}.1`, `${this.layerID}.2`]
    const sourceIds = [`${this.sourceID}.0`, `${this.sourceID}.1`, `${this.sourceID}.2`]

    layerIds.forEach((layerId) => {
      if (this.mapRef.getLayer(layerId)) {
        this.mapRef.removeLayer(layerId)
      }
    })
    sourceIds.forEach((sourceId) => {
      if (this.mapRef.getSource(sourceId)) {
        this.mapRef.removeSource(sourceId)
      }
    })
  }

  protected prepareRoute(route?: Coordinates[]): Coordinates[] {
    return route ?? [] // route?.length ? smoothPolyline(route) : []
  }

  protected calculateDirectionAngle(
    route: Coordinates[],
    atPoint: Coordinates,
  ): number | undefined {
    let directionAngle = getPolylineDirection(route, atPoint)
    // Inverse the angle and add 2 degrees for better arrow positioning
    if (directionAngle) {
      directionAngle = ((directionAngle + 180) % 360) + 2
    }
    return directionAngle
  }
}
