import { Injectable } from '@angular/core'
import { injectDestroy$, Memoize } from '@ti-platform/web/common'
import { BehaviorSubject, fromEvent, map, Observable, takeUntil, throttleTime } from 'rxjs'

export type OSName = 'Android' | 'iOS' | 'Linux' | 'macOS' | 'Windows' | 'unknown'
export type BrowserName = 'Chrome' | 'IE' | 'Edge' | 'Opera' | 'Firefox' | 'Safari' | 'unknown'

@Injectable({
  providedIn: 'root',
})
export class DeviceService {
  protected readonly destroy$ = injectDestroy$()

  private _isMobile?: boolean

  private readonly deviceWidth$ = new BehaviorSubject(window.innerWidth)
  private readonly mobileDevices = [
    'Android',
    'iPhone',
    'iPad',
    'iPod',
    'BlackBerry',
    'Windows Phone',
  ]

  public readonly isMobileSmallBreakPoint$ = new BehaviorSubject<boolean>(false)
  public readonly isMobileBreakPoint$ = new BehaviorSubject<boolean>(false)
  public readonly isTabletSmallBreakPoint$ = new BehaviorSubject<boolean>(false)
  public readonly isTabletBreakPoint$ = new BehaviorSubject<boolean>(false)
  public readonly isLaptopBreakPoint$ = new BehaviorSubject<boolean>(false)
  public readonly isDesktopBreakPoint$ = new BehaviorSubject<boolean>(false)

  public constructor() {
    Promise.resolve().then(() => this.init())
  }

  @Memoize()
  get osName(): OSName {
    const userAgent = navigator.userAgent
    if (userAgent.indexOf('Win') !== -1) return 'Windows'
    else if (userAgent.indexOf('Mac') !== -1) return 'macOS'
    else if (userAgent.indexOf('X11') !== -1 || userAgent.indexOf('Linux') !== -1) return 'Linux'
    else if (/Android/i.test(userAgent)) return 'Android'
    else if (/iPhone|iPad|iPod/i.test(userAgent)) return 'iOS'
    return 'unknown'
  }

  @Memoize()
  get browserName(): BrowserName {
    const userAgent = navigator.userAgent
    if (/MSIE|Trident/i.test(userAgent)) return 'IE'
    else if (/Edg/i.test(userAgent)) return 'Edge'
    else if (/OPR|Opera/i.test(userAgent)) return 'Opera'
    else if (/Chrome/i.test(userAgent) && !/Edg/i.test(userAgent)) return 'Chrome'
    else if (/Safari/i.test(userAgent) && !/Chrome/i.test(userAgent)) return 'Safari'
    else if (/Firefox/i.test(userAgent)) return 'Firefox'
    return 'unknown'
  }

  public isMobile(): boolean {
    if (this._isMobile === undefined) {
      const userAgent = navigator.userAgent
      this._isMobile =
        this.deviceWidth$.getValue() <= 480 ||
        this.mobileDevices.some((device) => userAgent.includes(device))
    }
    return this._isMobile
  }

  @Memoize()
  get isMobilePlatform() {
    const userAgent = navigator.userAgent
    const hasTouchScreen = 'ontouchstart' in window || navigator.maxTouchPoints > 0
    return hasTouchScreen && this.mobileDevices.some((device) => userAgent.includes(device))
  }

  @Memoize()
  public get isMobile$(): Observable<boolean> {
    return this.deviceWidth$.pipe(map(() => this.isMobile()))
  }

  protected init() {
    this.checkBreakPoints()
    fromEvent(window, 'resize')
      .pipe(takeUntil(this.destroy$), throttleTime(500, undefined, { trailing: true }))
      .subscribe(() => {
        this._isMobile = undefined
        this.deviceWidth$.next(window.innerWidth)
        this.checkBreakPoints()
      })
  }

  protected checkBreakPoints() {
    const width = this.deviceWidth$.value
    this.isDesktopBreakPoint$.next(width <= 1920)
    this.isLaptopBreakPoint$.next(width <= 1280)
    this.isTabletBreakPoint$.next(width <= 1024)
    this.isTabletSmallBreakPoint$.next(width <= 768)
    this.isMobileBreakPoint$.next(width <= 480)
    this.isMobileSmallBreakPoint$.next(width <= 380)
  }
}
