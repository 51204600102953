import { ChangeDetectorRef, inject, Pipe, PipeTransform } from '@angular/core'
import { takeUntil } from 'rxjs'
import {
  FuelConsumptionType,
  MeasurementSystem,
  MeasurementSystemConfig,
  SeparatorType,
} from '@ti-platform/contracts'
import { formatCommaSeparatedNumber, injectDestroy$ } from '@ti-platform/web/common/utils'
import { UnitType } from '../contracts'
import {
  extractSystemPerUnit,
  I18NConfigProvider,
  UnitConversionService,
} from '../services/unit-conversion.service'

export interface FormatUnitTypeOptions {
  addLabel?: boolean
  precision?: number
  commaSeparated?: boolean
  usePreciseIfLess?: number | null
}

// TODO: Create tests for this pipe
@Pipe({ name: 'formatUnit', pure: false })
export class FormatUnitPipe implements PipeTransform {
  protected readonly changeDetector = inject(ChangeDetectorRef)
  protected readonly i18NConfigProvider = inject(I18NConfigProvider)
  protected readonly unitConversionService = inject(UnitConversionService)
  protected readonly destroy$ = injectDestroy$()

  protected lastValue?: number
  protected lastResult?: string
  protected lastSystem?: MeasurementSystem | FuelConsumptionType
  protected lastDecimalSeparator?: SeparatorType
  protected lastSystemConfig?: MeasurementSystemConfig
  protected lastUnitType?: UnitType
  protected lastOptions?: Required<FormatUnitTypeOptions>

  protected defaultOptions: Required<FormatUnitTypeOptions> = {
    addLabel: true,
    precision: 0,
    commaSeparated: true,
    usePreciseIfLess: null,
  }

  public constructor() {
    // Update the result automatically after measurement system changes
    this.i18NConfigProvider.config$.pipe(takeUntil(this.destroy$)).subscribe((config) => {
      this.lastSystemConfig = config.measurementSystem
      this.lastDecimalSeparator = config.decimalSeparator
      if (this.lastUnitType && this.lastValue && this.lastOptions) {
        this.updateValue(
          this.lastValue,
          this.lastUnitType,
          extractSystemPerUnit(this.lastSystemConfig, this.lastUnitType),
          this.lastOptions,
        )
      }
    })
  }

  public transform(
    value: number | undefined,
    unit: UnitType,
    options?: FormatUnitTypeOptions,
  ): string {
    if (
      value !== null &&
      value !== undefined &&
      (this.lastUnitType !== unit || this.lastValue !== value)
    ) {
      // Read measurement system config and update the value
      this.i18NConfigProvider.getConfig().then((config) => {
        this.lastSystemConfig = config.measurementSystem
        this.updateValue(
          value,
          unit,
          extractSystemPerUnit(config.measurementSystem, unit),
          options
            ? (Object.assign(this.defaultOptions, options) as Required<FormatUnitTypeOptions>)
            : this.defaultOptions,
        )
      })
    }
    return this.lastResult ?? ''
  }

  protected updateValue(
    value: number,
    unit: UnitType,
    system: MeasurementSystem | FuelConsumptionType,
    options: Required<FormatUnitTypeOptions>,
  ) {
    const promise = this.getUnitText(value, unit, options)
    this.lastUnitType = unit
    this.lastValue = value
    this.lastSystem = system
    this.lastOptions = options
    promise.then((formattedValue) => {
      this.lastResult = formattedValue
      this.changeDetector.markForCheck()
    })
  }

  protected async getUnitText(
    inputValue: number,
    unit: UnitType,
    options: Required<FormatUnitTypeOptions>,
  ): Promise<string> {
    const [value, unitLabel] = await this.unitConversionService.toUser(inputValue, unit)
    let fixedValue = value.toFixed(options.precision)
    if (options.precision > 0 && options.usePreciseIfLess && value >= options.usePreciseIfLess) {
      fixedValue = value.toFixed(0)
    }
    const formattedValue = options.commaSeparated
      ? formatCommaSeparatedNumber(
          fixedValue,
          // Set up a thousand separator different from decimal
          this.lastDecimalSeparator === SeparatorType.Dot ? ',' : '.',
        )
      : fixedValue
    return options.addLabel ? `${formattedValue} ${unitLabel}` : `${formattedValue}`
  }
}
