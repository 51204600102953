import { inject, Injectable } from '@angular/core'
import { SwPush } from '@angular/service-worker'
import { TRIGGERED_ALERT_NOTIFICATIONS } from '@ti-platform/contracts'
import { ApiService } from '@ti-platform/web/api'
import { injectDestroy$, Memoize } from '@ti-platform/web/common'
import { BrowserSessionStorage } from '@ti-platform/web/common/models'
import { BehaviorSubject, combineLatest, map, Observable, takeUntil, tap, timer } from 'rxjs'

@Injectable({ providedIn: 'root' })
export class SidebarBadgesModel {
  public readonly sessionStorage = inject(BrowserSessionStorage)
  public readonly apiService = inject(ApiService)
  public readonly unreviewedAlertsLastCount$ = new BehaviorSubject<number>(0)
  public readonly refreshUnreviewedAlertCount$ = new BehaviorSubject<void>(undefined)
  protected readonly destroy$ = injectDestroy$()
  protected readonly pushes = inject(SwPush)

  @Memoize()
  public get state() {
    return {
      counters$: this.countersDataStream(),
    }
  }

  public init() {
    combineLatest([timer(100, 60000), this.refreshUnreviewedAlertCount$])
      .pipe(
        takeUntil(this.destroy$),
        tap(async () => {
          await this.loadUnreviewedAlertsCount()
        }),
      )
      .subscribe()

    // update alert badge on get alert notification push
    this.pushes.messages.pipe(takeUntil(this.destroy$)).subscribe((msg: Record<string, any>) => {
      if (msg?.data?.type && TRIGGERED_ALERT_NOTIFICATIONS.includes(msg.data.type)) {
        this.refreshUnreviewedAlertCount$.next()
      }
    })
  }

  // can be other counters
  public countersDataStream(): Observable<Record<string, any>> {
    return combineLatest([this.unreviewedAlertsLastCount$]).pipe(
      takeUntil(this.destroy$),
      map(([unreviewedAlerts]) => {
        return {
          unreviewedAlerts,
        }
      }),
    )
  }

  public refreshUnreviewedAlertsCount() {
    this.refreshUnreviewedAlertCount$.next()
  }

  public async loadUnreviewedAlertsCount() {
    try {
      const number = await this.apiService.fleet.triggeredAlert.unreviewedCount()
      if (number !== null && number !== undefined) {
        this.unreviewedAlertsLastCount$.next(number)
      }
    } catch (e) {
      console.error(e)
    }
  }
}
