import { Map as MaplibreGlMap } from 'maplibre-gl'
import { MaplibreComponentMarker } from '@ti-platform/web/map'
import { BaseMarker } from './base'
import { StaticVehicleMarkerComponent } from '../components'
import { ComponentFactory, Coordinates } from '../contracts'

export interface StaticVehicleMarkerOptions {
  id: string
  coordinates: Coordinates
  color: string
  icon: string
}

export abstract class AbstractStaticVehicleMarker extends BaseMarker {
  public override readonly options!: StaticVehicleMarkerOptions
}

export class MaplibreStaticVehicleMarker extends AbstractStaticVehicleMarker {
  protected readonly marker!: MaplibreComponentMarker<StaticVehicleMarkerComponent>

  public constructor(
    public override readonly options: StaticVehicleMarkerOptions,
    protected override readonly mapRef: MaplibreGlMap,
    protected override readonly componentFactory: ComponentFactory<StaticVehicleMarkerComponent>,
  ) {
    super(options, mapRef, componentFactory)

    this.marker = new MaplibreComponentMarker(
      {
        icon: this.options.icon,
        color: this.options.color,
        latLng: this.options.coordinates,
      },
      this.mapRef,
      this.componentFactory,
      StaticVehicleMarkerComponent,
    )
  }

  public override destroy() {
    super.destroy()
    this.marker?.destroy()
  }
}
