export * from './geofence'
export * from './homebase-provider'

import { ComponentRef, Type } from '@angular/core'

export type Coordinates = [number, number]
export type ComponentFactory<T extends object> = (
  componentType: Type<T>,
  options: Partial<T>,
) => Promise<ComponentRef<T>>

export type MapBounds = {
  getEast: () => number
  getWest: () => number
  getNorth: () => number
  getSouth: () => number
}

export interface iMarkerOptions {
  latLng: Coordinates
  sizePx: Coordinates
}

export interface iVehicleMarkerOptions extends iMarkerOptions {
  id: string
  name: string
  color: string
  icon: string
  status: VehicleStatus
  statusInvalid?: boolean
  directionDeg: number
  isLightMode?: boolean
}

export enum VehicleStatus {
  Stopped = 0,
  Idling = 1,
  Running = 2,
}
