import { AlertTriggerTypes } from '@ti-platform/contracts/alerts'

export enum PricingPlans {
  TnT = 0,
  Basic = 1,
  Standard = 2,
  Professional = 3,
}

export interface PricingPlan {
  id: PricingPlans
  name: string
}

export enum AppFeatures {
  GlobalSearch = 'global-search',
  GroupsAndLabels = 'groups-and-labels',
  Bookmarks = 'bookmarks',
  Dashboard = 'dashboard',
  FleetDrivers = 'fleet.drivers',
  AlertsMovement = 'alerts.movement',
  AlertsDevice = 'alerts.device',
  AlertsDriver = 'alerts.driver',
  AlertsDrivingBehavior = 'alerts.driving-behavior',
  AlertsSensors = 'alerts.sensors',
  Video = 'video',
  Dispatch = 'dispatch',
  Maintenance = 'maintenance',
  ChatWithDrivers = 'chat-with-drivers',
  ReportsDrivingActivity = 'reports.driving-activity',
  ReportsTripHistory = 'reports.trip-history',
  ReportsStopHistory = 'reports.stop-history',
  ReportsLastActive = 'reports.last-active',
  ReportsVisitedLocations = 'reports.visited-locations',
  ReportsCustom = 'reports.custom',
  DriverAccess = 'driver-access',
}

const TNT_PLAN_FEATURES = [
  AppFeatures.AlertsMovement,
  AppFeatures.ReportsDrivingActivity,
  AppFeatures.ReportsTripHistory,
  AppFeatures.ReportsStopHistory,
  AppFeatures.ReportsLastActive,
]

const BASIC_PLAN_FEATURES = [
  ...TNT_PLAN_FEATURES,
  AppFeatures.GlobalSearch,
  AppFeatures.Video,
  AppFeatures.AlertsDevice,
  AppFeatures.ReportsVisitedLocations,
]

const STANDARD_PLAN_FEATURES = [
  ...BASIC_PLAN_FEATURES,
  AppFeatures.GroupsAndLabels,
  AppFeatures.Bookmarks,
  AppFeatures.Dashboard,
  AppFeatures.FleetDrivers,
  AppFeatures.AlertsDriver,
  AppFeatures.AlertsDrivingBehavior,
  AppFeatures.AlertsSensors,
  AppFeatures.ChatWithDrivers,
  AppFeatures.DriverAccess,
]

const PROFESSIONAL_PLAN_FEATURES = [
  ...STANDARD_PLAN_FEATURES,
  AppFeatures.Dispatch,
  AppFeatures.Maintenance,
  AppFeatures.ReportsCustom,
]

export const PricingPlanFeatureMap: Record<PricingPlans, AppFeatures[]> = {
  [PricingPlans.TnT]: TNT_PLAN_FEATURES,
  [PricingPlans.Basic]: BASIC_PLAN_FEATURES,
  [PricingPlans.Standard]: STANDARD_PLAN_FEATURES,
  [PricingPlans.Professional]: PROFESSIONAL_PLAN_FEATURES,
}

// TODO: Find better place for this mapping
export const alertTriggerTypeFeatureMap: Record<
  AlertTriggerTypes,
  Array<
    | AppFeatures.AlertsMovement
    | AppFeatures.AlertsDevice
    | AppFeatures.AlertsDriver
    | AppFeatures.AlertsDrivingBehavior
    | AppFeatures.AlertsSensors
  >
> = {
  [AlertTriggerTypes.movement]: [AppFeatures.AlertsMovement],
  [AlertTriggerTypes.ignition]: [AppFeatures.AlertsMovement],
  [AlertTriggerTypes.engine_idling]: [AppFeatures.AlertsMovement],
  [AlertTriggerTypes.geofence_activity]: [AppFeatures.AlertsMovement],
  [AlertTriggerTypes.towing]: [AppFeatures.AlertsMovement],
  [AlertTriggerTypes.speeding]: [AppFeatures.AlertsMovement],
  [AlertTriggerTypes.sos_alarm]: [],
}
