import { ApiContext } from '../../contracts'
import { CommandsRoute } from './routes/commands.route'
import { DevicesRoute } from './routes/devices.route'
import { FleetRoute } from './routes/fleet.route'
import { UserRoute } from './routes/user.route'
import { WhiteLabelRoute } from './routes/white-label.route'

export class TspScope {
  public readonly user!: UserRoute
  public readonly devices!: DevicesRoute
  public readonly fleet!: FleetRoute
  public readonly commands!: CommandsRoute
  public readonly whiteLabel!: WhiteLabelRoute
  public constructor(context: ApiContext) {
    this.user = new UserRoute(context)
    this.devices = new DevicesRoute(context)
    this.fleet = new FleetRoute(context)
    this.commands = new CommandsRoute(context)
    this.whiteLabel = new WhiteLabelRoute(context)
  }
}
