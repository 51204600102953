import { Map as MaplibreGlMap, Marker } from 'maplibre-gl'
import { StopMarkerComponent } from '../components'
import { Coordinates, ComponentFactory } from '../contracts'
import { toLngLat } from '../utils'
import { BaseMarker } from './base'
import { ComponentRef } from '@angular/core'

export interface StopMarkerOptions {
  tripId: number
  coordinates: Coordinates
}

export abstract class AbstractStopMarker extends BaseMarker {
  public override readonly options!: StopMarkerOptions
}

export class MaplibreStopMarker extends AbstractStopMarker {
  protected readonly nativeRef!: Marker
  protected componentRef?: ComponentRef<StopMarkerComponent>

  public constructor(
    public override readonly options: StopMarkerOptions,
    protected override readonly mapRef: MaplibreGlMap,
    protected override readonly componentFactory: ComponentFactory<StopMarkerComponent>,
  ) {
    super(options, mapRef, componentFactory)

    this.nativeRef = new Marker({
      element: document.createElement('div'),
    }).setLngLat(toLngLat(this.options.coordinates))

    this.render()
  }

  protected render() {
    if (!this.componentRef) {
      this.componentFactory(StopMarkerComponent, {}).then((ref) => {
        this.nativeRef.getElement().appendChild(ref.location.nativeElement)
        this.nativeRef.addTo(this.mapRef)
        this.componentRef = ref
      })
    }
  }

  public override destroy() {
    super.destroy()
    this.nativeRef.remove()
    this.componentRef?.destroy()
  }
}
