// index.ts
import { distance } from "@turf/distance";
function square(bbox) {
  var west = bbox[0];
  var south = bbox[1];
  var east = bbox[2];
  var north = bbox[3];
  var horizontalDistance = distance(bbox.slice(0, 2), [east, south]);
  var verticalDistance = distance(bbox.slice(0, 2), [west, north]);
  if (horizontalDistance >= verticalDistance) {
    var verticalMidpoint = (south + north) / 2;
    return [west, verticalMidpoint - (east - west) / 2, east, verticalMidpoint + (east - west) / 2];
  } else {
    var horizontalMidpoint = (west + east) / 2;
    return [horizontalMidpoint - (north - south) / 2, south, horizontalMidpoint + (north - south) / 2, north];
  }
}
var turf_square_default = square;
export { turf_square_default as default, square };
