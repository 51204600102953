import { AfterViewInit, Component, EventEmitter, Input, Output } from '@angular/core'

@Component({
  selector: 'app-map-static-vehicle-marker',
  templateUrl: 'static-vehicle-marker.component.html',
})
export class StaticVehicleMarkerComponent implements AfterViewInit {
  @Input() color!: string
  @Input() icon!: string
  @Output() afterViewInit = new EventEmitter()

  public ngAfterViewInit() {
    this.afterViewInit.emit()
  }
}
