import { Component, inject, Input, OnInit } from '@angular/core'
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog'
import { In } from 'typeorm'

export interface ConfirmDialogData {
  summary?: string
  description?: string
  confirmButton?: string
  confirmButtonIcon?: string
  cancelButton?: string
  cancelButtonColor?: string
  confirmationMessage?: string
  reverseButtons?: boolean
}

@Component({
  selector: 'app-confirm-dialog',
  templateUrl: 'confirm-dialog.component.html',
  styleUrl: 'confirm-dialog.component.scss',
})
export class ConfirmDialogComponent implements OnInit, ConfirmDialogData {
  @Input() summary = ''
  @Input() description = ''
  @Input() confirmButton = 'Confirm'
  @Input() confirmButtonIcon?: string
  @Input() cancelButton = 'Cancel'
  @Input() cancelButtonColor = ''
  @Input() confirmationMessage?: string
  @Input() reverseButtons = false

  protected readonly dynamicDialogRef = inject(DynamicDialogRef)
  protected readonly dynamicDialogConfig = inject(DynamicDialogConfig)

  protected confirmInputValue?: string

  public ngOnInit() {
    // Assign data from dynamic dialog config
    Object.assign(this, this.dynamicDialogConfig.data)
  }

  get confirmDisabled() {
    if (!this.confirmationMessage) {
      return false
    } else {
      return this.confirmInputValue !== this.confirmationMessage
    }
  }
}
