import { inject, Injectable } from '@angular/core'
import { MessageService } from 'primeng/api'
import { ApiErrors } from '@ti-platform/contracts'
import { HttpException } from '@ti-platform/web/api'
import { Profile } from '@ti-platform/web/auth'
import {
  checkExistApiError,
  getApiErrorKey,
  getMessageFromException,
  injectDestroy$,
} from '@ti-platform/web/common'
import { LanguageService } from '@ti-platform/web/ui-kit/i18n'

@Injectable({ providedIn: 'root' })
export class ErrorHandler {
  protected readonly profile = inject(Profile)
  protected readonly messageService = inject(MessageService)
  protected readonly languageService = inject(LanguageService)
  protected readonly destroy$ = injectDestroy$()

  protected readonly DEMO_USER_MESSAGE_KEY = 'users.demo-role-message'
  protected readonly TRY_LATER_MESSAGE_KEY = 'common.please-try-again-later'

  public async notifyUser(error: unknown, description: string) {
    const isDemoUser = await this.profile.isDemoUser()
    const errorMessage = getMessageFromException(error)
    const showPleaseTryLaterMessage =
      errorMessage === ApiErrors.authUserInviteTimeout ||
      errorMessage === ApiErrors.authResetPasswordTimeout

    let summary = getApiErrorKey(ApiErrors.unknown),
      severity: 'warn' | 'error' = 'error'

    if (error instanceof HttpException) {
      if (isDemoUser && errorMessage === ApiErrors.accessDenied) {
        summary = await this.languageService.translate(this.DEMO_USER_MESSAGE_KEY)
        severity = 'warn'
      } else if (checkExistApiError(errorMessage)) {
        summary = await this.languageService.translate(getApiErrorKey(errorMessage))
        console.error(description ?? `Unknown error`, error)
      }
    }

    this.messageService.add({
      summary,
      severity,
      life: 5000,
      detail: showPleaseTryLaterMessage
        ? await this.languageService.translate(this.TRY_LATER_MESSAGE_KEY)
        : '',
    })
  }

  public async showUnknownErrorMessage() {
    const message = await this.languageService.translate(getApiErrorKey(ApiErrors.unknown))
    this.messageService.add({ severity: 'error', detail: message, life: 3000 })
  }
}
