<!-- date button-->
@if ({ isMobile: deviceService.isMobile$ | async }; as view) {
  <p-button
    (click)="dateRangePickerPanel.toggle($event); dateRangePicker.triggerHighlightUpdate()"
    [ngClass]="{ flat: view.isMobile }"
    [severity]="$any(view.isMobile ? null : 'secondary')"
    [outlined]="view.isMobile"
    class="fw-btn date-time-button text-14 fw-600 h--40 p--16 {{ btnClass }}"
  >
    <app-icon-date [height]="20" [width]="20" class="icon"></app-icon-date>
    @if (dateRange) {
      @switch (dateRange) {
        @case (PreDefinedRange.Today) {
          {{ 'date-range.today' | translate }}
        }
        @case (PreDefinedRange.Yesterday) {
          {{ 'date-range.yesterday' | translate }}
        }
        @case (PreDefinedRange.Last7Days) {
          {{ 'date-range.last-7-days' | translate }}
        }
        @case (PreDefinedRange.Last30Days) {
          {{ 'date-range.last-30-days' | translate }}
        }
        @default {
          @if (dateRange[0] && dateRange[1]) {
            @if (isOneDay && !showTime) {
              {{ dateRange[0] | formatDate: 'grid-date' }}
            } @else if (showTime) {
              {{ dateRange[0] | formatDate: 'grid-date-time' }} -
              {{ dateRange[1] | formatDate: 'grid-date-time' }}
            } @else {
              {{ dateRange[0] | formatDate: 'grid-date' }} -
              {{ dateRange[1] | formatDate: 'grid-date' }}
            }
          }
        }
      }
    }
  </p-button>
}

<div
  class="backdrop"
  [ngClass]="{ hidden: !dateRangePickerPanel.overlayVisible }"
  (click)="dateRangePickerPanel.hide()"
></div>

<!-- date-range picker -->
<p-overlayPanel #dateRangePickerPanel appendTo="body" styleClass="custom-overlay-panel">
  <app-date-range-picker
    #dateRangePicker
    (cancelClicked)="dateRangePickerPanel.hide()"
    (savedClicked)="onSavedClicked($event); dateRangePickerPanel.hide()"
    [disabled]="disabled"
    [numberOfMonths]="numberOfMonths"
    [value]="dateRange"
    [withTime]="withTime"
  ></app-date-range-picker>
</p-overlayPanel>
