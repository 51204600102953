import { ApiRoute } from '../../../services/api.route'
import { MeasurementSystemConfig } from '@ti-platform/contracts'

export class SettingsRoute extends ApiRoute {
  public override init() {
    this.route = 'fleet/settings'
  }

  public update(data: UpdateFleetSettingsData) {
    return this.post().withData(data).endpoint(`update`)
  }
}

interface UpdateFleetSettingsData {
  measurementSystem?: MeasurementSystemConfig
  address?: string
  city?: string
  region?: string
  zipCode?: string
  matchGeofenceNames?: boolean
}
