<p-button
  class="h-40 p-18 text-14 fw-500"
  [icon]="compact ? 'pi' : ''"
  [severity]="severity"
  (click)="exportMenu.show($event)"
>
  <app-icon-export [height]="20" [width]="20" class="icon"></app-icon-export>
  @if (!compact) {
    {{ 'button.export' | translate }}
  }
</p-button>

<p-menu
  #exportMenu
  (mouseleave)="exportMenu.hide()"
  [model]="(items$ | async) || []"
  [popup]="true"
  styleClass="export-menu"
>
  <ng-template pTemplate="start">
    <div class="menu-start">
      <div class="menu-start__title">{{ 'button.export' | translate }}</div>
      <div class="menu-start__description">{{ 'export.description' | translate }}</div>
    </div>
  </ng-template>
  <ng-template pTemplate="item" let-item>
    <a pRipple class="flex align-items-center p-menuitem-link export-menu-item">
      @switch (item.type) {
        @case (ExportFormat.Excel) {
          <app-icon-xls-file class="mr-8" width="20px" height="20px"></app-icon-xls-file>
        }
        @case (ExportFormat.CSV) {
          <app-icon-csv-file class="mr-8" width="20px" height="20px"></app-icon-csv-file>
        }
      }
      <span class="ml-2 menu-item-label">{{ item.label }}</span>
    </a>
  </ng-template>
</p-menu>
