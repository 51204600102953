import {
  ChannelIdentTrafficData,
  CreateDeviceData,
  DataQueryProps,
  Device,
  DeviceType,
  DoubleDeviceCheckData,
  EditDeviceData,
  GetDeviceTypeDto,
  SensorOptionsData,
  TspDeviceListData,
  TspDevicesCount,
} from '@ti-platform/contracts'
import { stripEmptyKeys } from '@ti-platform/web/common'
import { ApiRoute } from '../../../services/api.route'

export interface DevicesListDto {
  limit?: number
  page?: number
  orderBy?: 'name' | 'createdAt' | 'fleet.name' | 'identifier' | 'model' | 'sim'
  orderDirection?: 'ASC' | 'DESC'
  search?: string
  fleetId?: number
}

export enum DeviceProcessingStatuses {
  done_completely = 'done',
  done_partially = 'done_partially',
  not_done = 'not_done',
}

export interface DeviceProcessingResponse {
  device: Record<string, any> | null
  status: DeviceProcessingStatuses
}

export class DevicesRoute extends ApiRoute {
  public override init() {
    this.route = 'tsp/devices'
  }

  public list(options: DataQueryProps) {
    const data: Record<string, any> = stripEmptyKeys(options)
    Object.keys(data)?.forEach((key) => {
      data[key] = key === 'filter' ? JSON.stringify(options.filter) : `${data[key]}`
    })
    return this.get<{ devices: TspDeviceListData[]; countAll: number }>()
      .withParams(data)
      .endpoint('list')
  }

  public count() {
    return this.get<TspDevicesCount>().endpoint('count')
  }

  public typesData(options?: GetDeviceTypeDto) {
    const data = options ? stripEmptyKeys(options) : {}
    Object.keys(data)?.forEach((key) => {
      data[key] = `${data[key]}`
    })
    return this.get<DeviceType[]>().withParams(data).endpoint('types-data')
  }

  public telemetry(options?: { devicesIds?: string[]; parameters?: string[] }) {
    const data = options ? stripEmptyKeys(options) : {}
    Object.keys(data)?.forEach((key) => {
      data[key] = data[key]?.length ? data[key].join(',') : ''
    })
    return this.get<Record<string, any>[]>().withParams(data).endpoint('telemetry')
  }

  public trafficData(deviceId: string) {
    return this.get<ChannelIdentTrafficData | null>().endpoint(`${deviceId}/traffic-data`)
  }

  public checkDouble(data: DoubleDeviceCheckData) {
    data = data ? stripEmptyKeys(data) : {}
    const params: Record<string, string> = {}
    Object.keys(data)?.forEach((key) => {
      params[key] = `${data[key as keyof typeof data]}`
    })
    return this.get<boolean>().withParams(params).endpoint('check-exists-double')
  }

  public checkAssignFleet(fleetId: number) {
    const params = {
      fleetId: fleetId.toString(),
    }
    return this.get<boolean>().withParams(params).endpoint('check-fleet-on-assignment')
  }

  public createDevice(deviceData: CreateDeviceData) {
    return this.post<DeviceProcessingResponse>().withData(deviceData).endpoint('create')
  }

  public editDevice(deviceId: string, deviceData: EditDeviceData) {
    return this.put<DeviceProcessingResponse>().withData(deviceData).endpoint(`${deviceId}`)
  }

  public deleteDevice(deviceId: string) {
    return this.delete<DeviceProcessingResponse>().endpoint(`${deviceId}`)
  }

  public unassignDeviceFromFleet(deviceId: string) {
    return this.post<Device>().withData({}).endpoint(`${deviceId}/unassign-fleet`)
  }

  public assignDeviceToFleet(deviceId: string, fleetId: number) {
    return this.post<Device>().withData({ fleetId }).endpoint(`${deviceId}/assign-fleet`)
  }

  public getDeviceSensorsOptions(deviceId: string) {
    return this.get<SensorOptionsData>().endpoint(`${deviceId}/sensors-options`)
  }

  public setDeviceCommands(deviceId: string, commandIds: string[]) {
    return this.post<string[]>().withData({ commandIds }).endpoint(`${deviceId}/set-commands`)
  }

  public listAssignedToCommand(commandId: string, options: DataQueryProps) {
    const data: Record<string, any> = stripEmptyKeys(options)
    Object.keys(data)?.forEach((key) => {
      data[key] = key === 'filter' ? JSON.stringify(options.filter) : `${data[key]}`
    })
    return this.get<Partial<TspDeviceListData>[]>()
      .withParams(data)
      .endpoint(`assigned-to-command-list/${commandId}`)
  }

  public countAssignedToCommand(commandId: string) {
    return this.get<number>().endpoint(`assigned-to-command-count/${commandId}`)
  }

  public listNotAssignedToCommand(commandId: string, options: DataQueryProps) {
    const data: Record<string, any> = stripEmptyKeys(options)
    Object.keys(data)?.forEach((key) => {
      data[key] = key === 'filter' ? JSON.stringify(options.filter) : `${data[key]}`
    })
    return this.get<Partial<TspDeviceListData>[]>()
      .withParams(data)
      .endpoint(`not-assigned-to-command-list/${commandId}`)
  }

  public countNotAssignedToCommand(commandId: string) {
    return this.get<number>().endpoint(`not-assigned-to-command-count/${commandId}`)
  }
}
