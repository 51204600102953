import { Injectable } from '@angular/core'
import { saveAs } from 'file-saver'

@Injectable({
  providedIn: 'root',
})
export class ReportBuilder {
  async generateCSV(columnMap: { [key: string]: string }, rows: any[]): Promise<void> {
    const { Parser } = await import('@json2csv/plainjs')
    const columns = Object.values(columnMap)
    const mappedRows = rows.map((row) => this.mapRow(row, columnMap))
    const json2csvParser = new Parser({ fields: columns })
    const csv = json2csvParser.parse(mappedRows)
    const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' })
    saveAs(blob, 'report.csv')
  }

  async generateExcel(columnMap: { [key: string]: string }, rows: any[]): Promise<void> {
    const XLSX = await import('xlsx')
    const columns = Object.values(columnMap)
    const mappedRows = rows.map((row) => this.mapRow(row, columnMap))
    const worksheetData = [columns, ...mappedRows.map((row) => columns.map((col) => row[col]))]
    const worksheet = XLSX.utils.aoa_to_sheet(worksheetData)
    const workbook = XLSX.utils.book_new()
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Report')
    const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' })
    const blob = new Blob([excelBuffer], { type: 'application/octet-stream' })
    saveAs(blob, 'report.xlsx')
  }

  private mapRow(row: any, columnMap: { [key: string]: string }): any {
    const mappedRow: Record<string, any> = {}
    for (const [propertyName, columnTitle] of Object.entries(columnMap)) {
      mappedRow[columnTitle] = row[propertyName]
    }
    return mappedRow
  }
}
