import { Point } from 'typeorm'
import { AlertTriggerTypes } from '../alerts'

export interface TriggeredAlert {
  id: string
  name: string
  status: TriggeredAlertStatuses
  type: AlertTriggerTypes
  location: Point
  address: string
  metadata: object
  calculatorId: number
  intervalId: number
  intervalData: Record<string, any>
  alertId: string
  vehicleId: string
  deviceId: string
  externalDeviceId: number
  tspId: number
  fleetId: number
  createdAt: Date
  deleted: boolean
  deletedAt: Date
  triggeredAt: Date
}

export enum TriggeredAlertStatuses {
  Unreviewed = 0,
  Unacknowledged = 1,
  Acknowledged = 2,
  Reviewed = 3,
  FalsePositive = 4,
}
