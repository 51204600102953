import { inject, Injectable } from '@angular/core'
import { ResolveEnd, Router } from '@angular/router'
import { injectDestroy$ } from '@ti-platform/web/common'
import { BehaviorSubject, filter, takeUntil } from 'rxjs'

@Injectable({ providedIn: 'root' })
export class RouterNavigationHistoryProvider {
  protected readonly destroy$ = injectDestroy$()
  protected readonly router = inject(Router)
  public readonly currentUrlParts$ = new BehaviorSubject<string[] | null>(null)
  public readonly currentUrl$ = new BehaviorSubject<string | null>(null)
  public readonly previousUrlParts$ = new BehaviorSubject<string[] | null>(null)
  public readonly previousUrl$ = new BehaviorSubject<string | null>(null)

  public constructor() {
    this.router.events
      .pipe(
        takeUntil(this.destroy$),
        filter((event) => event instanceof ResolveEnd),
      )
      .subscribe((event) => {
        if (event instanceof ResolveEnd) {
          this.processUrl(event.urlAfterRedirects)
        }
      })
  }

  protected processUrl(url: string) {
    let newUrl = url === '/' ? '' : url
    if (newUrl.startsWith('/')) {
      newUrl = newUrl.substring(1, newUrl.length)
    }
    const newUrlParts = newUrl.split('/')
    this.previousUrl$.next(this.currentUrl$.value)
    this.previousUrlParts$.next(this.currentUrlParts$.value)
    this.currentUrl$.next(newUrl)
    this.currentUrlParts$.next(newUrlParts)
  }
}
