import { Map as MaplibreGlMap } from 'maplibre-gl'
import { TripMarkerComponent } from '../components'
import { Coordinates, ComponentFactory } from '../contracts'
import { BaseMarker } from './base'
import { toLngLat } from '../utils'
import { MaplibreComponentMarker } from '@ti-platform/web/map'

export abstract class AbstractTripMarker extends BaseMarker {
  public override readonly options!: TripMarkerOptions
}

export interface TripMarkerOptions {
  tripId: number
  coordinates: Coordinates[]
}

export class MaplibreTripMarker extends AbstractTripMarker {
  protected readonly color = '#7F56D9'
  protected readonly layerID!: string
  protected readonly sourceID!: string

  protected startMarker!: MaplibreComponentMarker<TripMarkerComponent>
  protected finishMarker!: MaplibreComponentMarker<TripMarkerComponent>

  public constructor(
    public override readonly options: TripMarkerOptions,
    protected override readonly mapRef: MaplibreGlMap,
    protected override readonly componentFactory: ComponentFactory<TripMarkerComponent>,
  ) {
    super(options, mapRef, componentFactory)

    this.layerID = `layer-trip-${options.tripId}`
    this.sourceID = `source-trip-${options.tripId}`

    this.startMarker = new MaplibreComponentMarker(
      { style: 'start', latLng: this.options.coordinates[0] },
      this.mapRef,
      this.componentFactory,
      TripMarkerComponent,
    )

    this.finishMarker = new MaplibreComponentMarker(
      { style: 'finish', latLng: this.options.coordinates[this.options.coordinates.length - 1] },
      this.mapRef,
      this.componentFactory,
      TripMarkerComponent,
    )

    this.init()
  }

  public init() {
    this.initSource()
    this.initLayers()

    // Reinitialize the layers after style data is updated
    const repairAfterStyleRefresh = () => {
      this.initSource()
      this.initLayers()
    }
    this.mapRef.on('styledata', repairAfterStyleRefresh)
    this.destroy$.subscribe(() => this.mapRef.off('styledata', repairAfterStyleRefresh))
  }

  public override destroy() {
    super.destroy()
    this.mapRef.removeLayer(this.layerID)
    this.mapRef.removeSource(this.sourceID)
    this.startMarker.destroy()
    this.finishMarker.destroy()
  }

  protected initSource() {
    if (!this.mapRef.getSource(this.sourceID)) {
      this.mapRef.addSource(this.sourceID, {
        type: 'geojson',
        data: {
          id: 'trip-line',
          type: 'Feature',
          properties: {},
          geometry: {
            type: 'LineString',
            // Convert coordinates to LngLat format
            coordinates: this.options.coordinates.map((item) => toLngLat(item)),
          },
        },
      })
    }
  }

  protected initLayers() {
    if (!this.mapRef.getLayer(this.layerID)) {
      this.mapRef.addLayer({
        id: this.layerID,
        type: 'line',
        source: this.sourceID,
        layout: {
          'line-join': 'round',
          'line-cap': 'round',
        },
        paint: {
          'line-color': this.color,
          'line-width': 3,
        },
      })
    }
  }
}
