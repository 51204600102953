<p-dialog
  class="dialog-primary full headless dialog-content-flex"
  [visible]="isOpened()"
  (visibleChange)="close()"
  [modal]="true"
>
  <div class="flex-wrapper">
    <header>
      <app-icon-close
        (click)="close()"
        [width]="24"
        [height]="24"
        [color]="'var(--color-gray-900)'"
      ></app-icon-close>
    </header>
    <section class="content">
      @if (
        {
          currentLang: languageService.current$ | async,
          branding: brandingService.branding$ | async,
        };
        as view
      ) {
        @switch (step()) {
          @case (1) {
            <ng-container [ngTemplateOutlet]="step1Ref"></ng-container>
          }
          @case (2) {
            <ng-container
              [ngTemplateOutlet]="step2Ref"
              [ngTemplateOutletContext]="{ currentLang: view.currentLang }"
            ></ng-container>
          }
          @case (3) {
            <ng-container
              [ngTemplateOutlet]="step3Ref"
              [ngTemplateOutletContext]="{ currentLang: view.currentLang }"
            ></ng-container>
          }
          @case (4) {
            <ng-container
              [ngTemplateOutlet]="step4Ref"
              [ngTemplateOutletContext]="{ currentLang: view.currentLang, branding: view.branding }"
            ></ng-container>
          }
          @case (5) {
            <ng-container
              [ngTemplateOutlet]="step5Ref"
              [ngTemplateOutletContext]="{ branding: view.branding }"
            ></ng-container>
          }
        }
      }
    </section>
    <section class="footer">
      @if (step() !== 1) {
        <p-button
          [severity]="'secondary'"
          (click)="stepperFunction(step() - 1)"
          class="cancel w100 p--18 w50 h--40 text-14 fw-600"
          >Back</p-button
        >
      }

      @switch (step()) {
        @case (5) {
          <p-button class="w100 h--40 text-14 fw-600" (click)="close()">Close</p-button>
        }
        @default {
          <p-button class="w100 h--40 text-14 fw-600" (click)="stepperFunction(step() + 1)"
            >Continue</p-button
          >
        }
      }
    </section>
  </div>
</p-dialog>

<ng-template #step1Ref>
  <div class="image-container">
    <img
      class="preview"
      [src]="'./assets/images/pwa-install-guide/preview-1-safari-en.png'"
      alt=""
    />
    <div class="horizontal-spacer mb-24"></div>
  </div>
  <div class="content-wrapper">
    <div class="caption">{{ 'pwa-install-guide.install-mobile-app' | translate }}</div>
    <div class="description">
      {{ 'pwa-install-guide.quick-access' | translate }}
    </div>
  </div>
</ng-template>

<ng-template #step2Ref let-currentLang="currentLang">
  <div class="image-container mb-24">
    @switch (deviceService.browserName) {
      @case ('Chrome') {
        <img
          class="preview"
          [src]="
            currentLang === 'en'
              ? './assets/images/pwa-install-guide/preview-2-chrome-en.png'
              : './assets/images/pwa-install-guide/preview-2-chrome-es.png'
          "
          alt=""
        />
      }
      @case ('Safari') {
        <img
          class="preview"
          [src]="
            currentLang === 'en'
              ? './assets/images/pwa-install-guide/preview-2-safari-en.png'
              : './assets/images/pwa-install-guide/preview-2-safari-es.png'
          "
          alt=""
        />
      }
    }
    <img class="arrow" [src]="'./assets/images/pwa-install-guide/preview-arrow.png'" alt="" />
  </div>
  <div class="content-wrapper">
    <div class="download-card">
      <span class="description text">
        {{ 'pwa-install-guide.tap-on' | translate }}

        @switch (deviceService.browserName) {
          @case ('Chrome') {
            <app-icon-plus
              class="icon"
              [width]="20"
              [height]="20"
              [color]="'#0657DA'"
            ></app-icon-plus>
          }
          @case ('Safari') {
            <app-icon-safari-symbol
              class="icon"
              [width]="15"
              [height]="18"
              [color]="'#0657DA'"
            ></app-icon-safari-symbol>
          }
        }

        {{
          'pwa-install-guide.button-in-browser' | translate: { message: deviceService.browserName }
        }}
      </span>
    </div>
  </div>
</ng-template>

<ng-template #step3Ref let-currentLang="currentLang">
  <div class="image-container mb-24">
    @switch (deviceService.browserName) {
      @case ('Chrome') {
        <img
          class="preview"
          [src]="
            currentLang === 'en'
              ? './assets/images/pwa-install-guide/preview-3-chrome-en.png'
              : './assets/images/pwa-install-guide/preview-3-chrome-es.png'
          "
          alt=""
        />
      }
      @case ('Safari') {
        <img
          class="preview"
          [src]="
            currentLang === 'en'
              ? './assets/images/pwa-install-guide/preview-3-safari-en.png'
              : './assets/images/pwa-install-guide/preview-3-safari-es.png'
          "
          alt=""
        />
      }
    }
  </div>
  <div class="content-wrapper">
    <div class="download-card">
      <span class="description text">
        <span>{{ 'pwa-install-guide.select' | translate }}</span>
        <span class="ml-4 link">
          {{
            (deviceService.browserName === 'Safari'
              ? 'pwa-install-guide.add-to-home-screen'
              : 'pwa-install-guide.add-to-home-screen-chrome'
            ) | translate
          }}
        </span>
      </span>
    </div>
  </div>
</ng-template>

<ng-template #step4Ref let-currentLang="currentLang" let-branding="branding">
  <div class="image-container mb-24">
    <img
      class="preview"
      [src]="
        currentLang === 'en'
          ? './assets/images/pwa-install-guide/preview-4-safari-en.png'
          : './assets/images/pwa-install-guide/preview-4-safari-es.png'
      "
      alt=""
    />
    @if (branding) {
      <div class="custom-logo">
        <img [src]="branding?.logo?.sm?.href" alt="" />
      </div>
      <div class="custom-name">
        {{ branding?.name }}
      </div>
      <div class="custom-url">{{ baseUrl() }}</div>
    }
    <div class="horizontal-spacer"></div>
  </div>
  <div class="content-wrapper">
    <div class="download-card">
      <span class="description text">
        {{ 'pwa-install-guide.choose-name' | translate }}
      </span>
    </div>
  </div>
</ng-template>

<ng-template #step5Ref let-branding="branding">
  <div class="image-container mb-24">
    <img
      class="preview"
      [src]="'./assets/images/pwa-install-guide/preview-5-safari-en.png'"
      alt=""
    />
    @if (branding) {
      <div class="custom-logo phone-screen">
        <img [src]="branding?.logo?.sm?.href" alt="" />
      </div>
      <div class="custom-name phone-screen">
        {{ branding?.name }}
      </div>
    }
    <div class="horizontal-spacer"></div>
  </div>
  <div class="content-wrapper">
    <div class="download-card">
      <span class="description text"> {{ 'pwa-install-guide.find-place' | translate }} </span>
    </div>
  </div>
</ng-template>
