import { Component, Input, Type } from '@angular/core'
import {
  BicycleIconComponent,
  Bus1IconComponent,
  Bus1SideIconComponent,
  Bus2IconComponent,
  BusDoubleIconComponent,
  Car1IconComponent,
  CarCargoIconComponent,
  CarIconComponent,
  MclarenIconComponent,
  PoliceIconComponent,
  ScooterIconComponent,
  Taxi1IconComponent,
  TaxiIconComponent,
  TrolleybusIconComponent,
  TruckBigIconComponent,
  TruckCargoBigIconComponent,
  TruckCargoIconComponent,
  TruckHighwayIconComponent,
  TruckIconComponent,
} from '@ti-platform/web/ui-kit/icons/components'
import { BusIconComponent } from '@ti-platform/web/ui-kit/icons/components/transport/bus-icon.component'

@Component({
  selector: 'app-transport-icon-selector',
  templateUrl: 'transport-icon-selector.component.html',
  styleUrl: 'transport-icon-selector.component.scss',
})
export class TransportIconSelectorComponent {
  protected readonly iconsList = new Map<string, Type<unknown>>([
    ['bicycle', BicycleIconComponent],
    ['bus', BusIconComponent],
    ['bus-1', Bus1IconComponent],
    ['bus-1-side', Bus1SideIconComponent],
    ['bus-2', Bus2IconComponent],
    ['bus-double', BusDoubleIconComponent],
    ['car', CarIconComponent],
    ['car-1', Car1IconComponent],
    ['car-cargo', CarCargoIconComponent],
    ['mclaren', MclarenIconComponent],
    ['police', PoliceIconComponent],
    ['scooter', ScooterIconComponent],
    ['taxi', TaxiIconComponent],
    ['taxi-1', Taxi1IconComponent],
    ['trolleybus', TrolleybusIconComponent],
    ['truck', TruckIconComponent],
    ['truck-big', TruckBigIconComponent],
    ['truck-cargo', TruckCargoIconComponent],
    ['truck-cargo-big', TruckCargoBigIconComponent],
    ['truck-highway', TruckHighwayIconComponent],
  ])

  @Input() icon?: string
  @Input() color? = 'var(--icon-color)'
}
