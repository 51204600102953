import {
  DateStyle,
  LanguageCode,
  MeasurementSystemConfig,
  SeparatorType,
} from '@ti-platform/contracts'

export enum UnitType {
  Distance = 'distance',
  FuelEfficiency = 'fuel-efficiency',
  Pressure = 'pressure',
  Speed = 'speed',
  Temperature = 'temperature',
  Volume = 'volume',
  Voltage = 'voltage',
}

export enum FormatDurationTypes {
  Fleet = 'fleet',
  Alert = 'alert',
}

export interface I18NConfig {
  lang: LanguageCode
  decimalSeparator: SeparatorType
  dateStyle: DateStyle
  measurementSystem: MeasurementSystemConfig
}
