import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { FormsModule, ReactiveFormsModule } from '@angular/forms'
// App
import { LayoutModule } from './layout/layout.module'
import { PrimeNgModule } from './prime-ng/prime-ng.module'
import { IconsModule } from './icons/icons.module'
import { I18nModule } from './i18n/i18n.module'
import { BrandingService } from './services/branding.service'
import { PhoneFormatPipe } from '@ti-platform/web/ui-kit/pipes'

const MODULES = [
  // Angular
  CommonModule,
  FormsModule,
  ReactiveFormsModule,
  // App
  I18nModule,
  IconsModule,
  LayoutModule,
  PrimeNgModule,
]

const DECLARATIONS = [PhoneFormatPipe]

@NgModule({
  imports: [...MODULES],
  exports: [...MODULES, ...DECLARATIONS],
  declarations: [...DECLARATIONS],
  providers: [BrandingService],
})
export class UiKitModule {}
