import { Component } from '@angular/core'
import { BaseIconComponent } from '../base-icon.component'

@Component({
  selector: 'app-icon-gallery',
  template: `
    <svg
      [attr.width]="width"
      [attr.height]="height"
      viewBox="0 0 21 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.2331 18.3332H7.89974C3.73307 18.3332 2.06641 16.6665 2.06641 12.4998V7.49984C2.06641 3.33317 3.73307 1.6665 7.89974 1.6665H12.8997C17.0664 1.6665 18.7331 3.33317 18.7331 7.49984V10.8332"
        [attr.stroke]="color"
        style="stroke-opacity:1;"
        stroke-width="1.04167"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M7.90007 8.33333C8.82054 8.33333 9.56673 7.58714 9.56673 6.66667C9.56673 5.74619 8.82054 5 7.90007 5C6.97959 5 6.2334 5.74619 6.2334 6.66667C6.2334 7.58714 6.97959 8.33333 7.90007 8.33333Z"
        [attr.stroke]="color"
        style="stroke-opacity:1;"
        stroke-width="1.04167"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M2.625 15.7915L6.73333 13.0332C7.39167 12.5915 8.34167 12.6415 8.93333 13.1498L9.20833 13.3915C9.85833 13.9498 10.9083 13.9498 11.5583 13.3915L15.025 10.4165"
        [attr.stroke]="color"
        style="stroke-opacity:1;"
        stroke-width="1.04167"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M16.3996 18.6663C17.8723 18.6663 19.0662 17.4724 19.0662 15.9997C19.0662 14.5269 17.8723 13.333 16.3996 13.333C14.9268 13.333 13.7329 14.5269 13.7329 15.9997C13.7329 17.4724 14.9268 18.6663 16.3996 18.6663Z"
        [attr.stroke]="color"
        style="stroke-opacity:1;"
        stroke-width="1.04167"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M19.5662 19.1663L18.7329 18.333"
        [attr.stroke]="color"
        style="stroke-opacity:1;"
        stroke-width="1.04167"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  `,
  styles: [
    `
      :host {
        display: flex;
        align-items: center;
        justify-content: center;
      }
    `,
  ],
})
export class GalleryIconComponent extends BaseIconComponent {}
