export const PAGE_SIZE = 50
export type OrderType = 'ASC' | 'DESC'
export const vehicleDefaultIcon = 'car'
export const maxDevicesForDemoFleet = 3
export const phoneOrIccidPattern = '^([\\+][\\d]{9,15}|[\\d]{19,20})$'
export const phoneOrIccidRegExp = new RegExp(phoneOrIccidPattern)
export const vehicleDefaultColors = ['#34B6D3', '#EFCF30', '#B692F6', '#DE89E0']

export const defaultIgnitionParameter = 'engine.ignition.status'
export const defaultOdometerParameter = 'vehicle.mileage'
export const defaultBatteryParameter = 'external.powersource.voltage'

export const LOGIN_AS_HEADER_NAME = 'ti-login-as-session'
