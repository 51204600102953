import { Subject } from 'rxjs'
import { ComponentFactory } from '../contracts'

export abstract class BaseMarker {
  public constructor(
    public readonly options: any,
    protected readonly mapRef: any,
    protected readonly componentFactory: ComponentFactory<any>,
  ) {}

  public readonly destroy$ = new Subject<void>()
  public destroy() {
    this.destroy$.next()
    this.destroy$.complete()
  }
}
