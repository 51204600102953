import {
  EventEmitter,
  Component,
  Input,
  Output,
  ViewChild,
  ElementRef,
  AfterViewInit,
} from '@angular/core'
import { createTextMaskInputElement } from 'text-mask-core'
import { fromEvent, takeUntil } from 'rxjs'
import { injectDestroy$ } from '@ti-platform/web/common'

@Component({
  selector: 'app-time-input',
  templateUrl: 'time-input.component.html',
  styleUrls: ['time-input.component.scss'],
})
export class TimeInputComponent implements AfterViewInit {
  @Output() timeChanged = new EventEmitter<Date>()

  @ViewChild('timeInputRef')
  protected readonly timeInputRef?: ElementRef

  protected readonly destroy$ = injectDestroy$()

  protected time?: string
  protected period?: DayPeriod
  protected _date?: Date

  @Input() set date(value: Date) {
    if (this._date !== value) {
      this._date = value
      this.readTimeFromDate()
    }
  }

  public ngAfterViewInit() {
    if (this.timeInputRef?.nativeElement) {
      this.initTimeInputMask(this.timeInputRef.nativeElement)
    }
  }

  protected readTimeFromDate() {
    if (this._date) {
      this.time = formatTimeTo12H(this._date.getHours(), this._date.getMinutes())
      this.period = this._date.getHours() >= 12 ? 'pm' : 'am'
      // Force update the time-input element
      // this.timeInput?.writeValue(this.time)
    }
  }

  protected onTimeChanges(time?: string | null) {
    console.log(`onTimeChanges`, time)

    if (!time) {
      return this.readTimeFromDate()
    }

    const parsed = parseTimeFrom12H(time, this.period || 'am')
    if (parsed) {
      const time = formatTimeTo12H(parsed.hours, parsed.minutes)
      if (this.time !== time || this.period !== parsed.period) {
        this.time = formatTimeTo12H(parsed.hours, parsed.minutes)
        this.period = parsed.period
      }

      let hasChanges = false
      if (this?._date) {
        const date = new Date(this._date)
        if (this._date.getHours() !== parsed.hours) {
          date.setHours(parsed.hours)
          hasChanges = true
        }
        if (this._date.getMinutes() !== parsed.minutes) {
          date.setMinutes(parsed.minutes)
          hasChanges = true
        }
        if (hasChanges) {
          this.timeChanged.emit(date)
        }
      }
    } else {
      this.readTimeFromDate()
    }
  }

  protected onPeriodChanged(period: DayPeriod) {
    if (this.period !== period) {
      this.period = period
      if (this.time) {
        this.onTimeChanges(this.time)
      }
    }
  }

  protected initTimeInputMask(timeInput: HTMLElement) {
    const timeMask = () => [/\d/, /\d/, ':', /\d/, /\d/]

    const timePipe = (conformedValue: string) => {
      const numbers = conformedValue.replace(/\D/g, '')

      let hours = '00'
      let minutes: string

      if (numbers.length >= 1) {
        hours = numbers.slice(0, 2)
        if (hours.length === 1) {
          hours = '0' + hours // Pad single-digit hours with a leading zero
        }
      }

      if (numbers.length > 2) {
        // Get the minutes part
        minutes = numbers.slice(2, 4)
        if (minutes.length === 1) {
          minutes = minutes + '0' // Pad single-digit minutes with a trailing zero
        }
      } else {
        minutes = '00'
      }

      return hours + ':' + minutes
    }

    // Create the text mask input element
    const textMask = createTextMaskInputElement({
      inputElement: timeInput,
      mask: timeMask,
      pipe: timePipe,
      guide: false,
    })

    fromEvent(timeInput, 'keyup')
      .pipe(takeUntil(this.destroy$))
      .subscribe((event: Event) => {
        if ((event as KeyboardEvent).key === 'Enter') {
          timeInput.blur()
        } else {
          textMask.update()
        }
      })
  }
}

export type DayPeriod = 'am' | 'pm'

export const formatTimeTo12H = (hours: number, minutes: number) => {
  const hoursStr = `${hours % 12 || 12}`.padStart(2, '0')
  const minutesStr = `${minutes}`.padStart(2, '0')
  return `${hoursStr}:${minutesStr}`
}

// Returns time in 24h format
export const parseTimeFrom12H = (
  time: string,
  period: DayPeriod,
):
  | {
      hours: number
      minutes: number
      period: DayPeriod
    }
  | undefined => {
  if (!time.includes('_')) {
    let [hours, minutes] = time.split(':').map((v) => parseInt(v))
    if (hours > 12) {
      hours = hours % 12 || 12
      period = 'pm'
    }
    if (minutes >= 60) {
      minutes = 59
    }

    // Convert the hours to 24-hour format
    if (period.toLowerCase() === 'pm' && hours !== 12) {
      hours += 12
    } else if (period.toLowerCase() === 'am' && hours === 12) {
      hours = 0
    }

    return { hours, minutes, period }
  }
}
