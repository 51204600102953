import {
  DataQueryProps,
  LastActiveReportData,
  StopHistoryReportData,
  TripHistoryReportData,
  VehicleActivityData,
  VehicleDrivingActivityData,
} from '@ti-platform/contracts'
import { stripEmptyKeys } from '@ti-platform/web/common'
import { ApiRoute } from '../../../services/api.route'

export class ReportsRoute extends ApiRoute {
  public override init() {
    this.route = 'reports'
  }

  drivingActivity(params: DataQueryProps) {
    return this.get<VehicleDrivingActivityData[]>()
      .withParams(
        stripEmptyKeys({
          ...params,
          filter: params.filter ? JSON.stringify(params.filter) : '',
        }),
      )
      .endpoint('driving-activity')
  }

  vehicleActivity(vehicleId: string, params: DataQueryProps) {
    return this.get<VehicleActivityData>()
      .withParams(
        stripEmptyKeys({
          ...params,
          filter: params.filter ? JSON.stringify(params.filter) : '',
        }),
      )
      .endpoint(`vehicle-activity/${vehicleId}`)
  }

  tripHistory(params: DataQueryProps) {
    return this.get<TripHistoryReportData>()
      .withParams(
        stripEmptyKeys({
          ...params,
          filter: params.filter ? JSON.stringify(params.filter) : '',
        }),
      )
      .endpoint('trip-history')
  }

  tripRoute(vehicleId: string, intervalId: number) {
    return this.get<Record<string, any> | null>()
      .withParams({
        vehicleId,
        intervalId,
      })
      .endpoint('trip-interval')
  }

  lastActive(params: DataQueryProps) {
    return this.get<LastActiveReportData[]>()
      .withParams(
        stripEmptyKeys({
          ...params,
          filter: params.filter ? JSON.stringify(params.filter) : '',
        }),
      )
      .endpoint('last-active')
  }

  stopHistory(params: DataQueryProps) {
    return this.get<StopHistoryReportData>()
      .withParams(
        stripEmptyKeys({
          ...params,
          filter: params.filter ? JSON.stringify(params.filter) : '',
        }),
      )
      .endpoint('stop-history')
  }
}
