import { inject } from '@angular/core'
import { DataQueryProps, PAGE_SIZE } from '@ti-platform/contracts'
import { ApiService } from '@ti-platform/web/api'
import {
  BrowserSessionStorage,
  CONFIG,
  DateRange,
  getDateRangeFromPredefined,
  injectDestroy$,
  isPreDefinedRange,
  ListModelConfig,
  ListModelStore,
  OrderByData,
  PreDefinedRange,
  ReportBuilder,
  RouterNavigationHistoryProvider,
  stripEmptyKeys,
} from '@ti-platform/web/common'
import { LanguageService } from '@ti-platform/web/ui-kit/i18n'
import { DataGridColumn, DataGridSortOrder } from '@ti-platform/web/ui-kit/layout/components'
import {
  BehaviorSubject,
  combineLatest,
  debounceTime,
  map,
  Observable,
  shareReplay,
  switchMap,
  takeUntil,
  tap,
} from 'rxjs'
import { ExportFormat, FilterOptions, ReportColumn } from '../contracts'
import { GetPagesDataDto, ListModelState } from './types'

export abstract class ListModel<Data, State extends ListModelState<Data> = ListModelState<Data>> {
  protected readonly api = inject(ApiService)
  protected readonly sessionStorage = inject(BrowserSessionStorage)
  protected readonly languageService = inject(LanguageService)
  protected readonly env = inject(CONFIG)
  protected readonly destroy$ = injectDestroy$()
  protected readonly reportBuilder = inject(ReportBuilder)
  protected readonly routerNavigationHistoryProvider = inject(RouterNavigationHistoryProvider)
  protected readonly _exportColumns: ReportColumn[] = []

  get exportColumns() {
    let columns = this._exportColumns?.length
      ? this._exportColumns
      : this.props.gridColumns.map((column) => ({
          field: column.field ?? '',
          labelTranslation: column.label ?? '',
        }))
    if (this.store.columnsExportLimit$.value?.length) {
      columns = columns.filter((item) => this.store.columnsExportLimit$.value.includes(item.field))
    }
    return columns
  }

  private _state: State | undefined

  public get state(): State {
    if (!this._state) {
      this._state = this.loadState()
    }
    return this._state
  }

  private _props: ListModelConfig | undefined = undefined

  protected get props(): ListModelConfig {
    if (!this._props) {
      this._props = this.config()
    }
    return this._props
  }

  // State
  private _store: ListModelStore<Data> | undefined = undefined

  protected get store(): ListModelStore<Data> {
    if (!this._store) {
      this._store = this.loadStore()
    }
    return this._store
  }

  protected get searchStorageKey() {
    return `TI_LIST_${this.props.name.toUpperCase()}_SEARCH`
  }

  protected get sortOrderStorageKey() {
    return `TI_LIST_${this.props.name.toUpperCase()}_SORT_ORDER`
  }

  protected get filterStorageKey() {
    return `TI_LIST_${this.props.name.toUpperCase()}_FILTER`
  }

  protected get dateRangeStorageKey() {
    return `TI_LIST_${this.props.name.toUpperCase()}_DATE_RANGE`
  }

  public async exportReport(format = ExportFormat.CSV) {
    const translatedColumns = await Promise.all(
      this.exportColumns.map(async (column) => {
        return {
          field: column.field,
          label: await this.languageService.translate(column.labelTranslation ?? column.field),
        }
      }),
    )
    const columnsMap = translatedColumns.reduce(
      (acc, column) => {
        acc[column.field] = column.label ?? ''
        return acc
      },
      {} as Record<string, string>,
    )
    let data: Data[] = []
    for (const page of this.store.loadedPages.values()) {
      data = data.concat(page)
    }

    const convertedData = await this.convertDataToExport(data)
    if (format === ExportFormat.CSV) {
      await this.reportBuilder.generateCSV(columnsMap, convertedData)
    } else {
      await this.reportBuilder.generateExcel(columnsMap, convertedData)
    }
  }

  protected async convertDataToExport(data: Data[]): Promise<any[]> {
    return data
  }

  public reset() {
    this.store.loadedPages.clear()
    this.store.canLoadNextPage$.next(true)
    this.store.page$.next(0)
    this.store.search$.next('')
    this.store.selectedItem$.next(null)
    this.store.filter$.next(null)
    this.store.dateRange$.next(this.props.defaultDateRange || PreDefinedRange.Today)
    this.reloadCount()
  }

  public refresh() {
    this.store.loadedPages.clear()
    this.store.page$.next(this.store.page$.value)
  }

  public setOrder(order: OrderByData) {
    if (order.column) {
      this.store.gridSortOrder$.next(order)
      this.sessionStorage.setItem(this.sortOrderStorageKey, JSON.stringify(order))
      this.store.loadedPages.clear()
      this.store.canLoadNextPage$.next(true)
      this.store.page$.next(0)
      this.store.orderBy$.next({ column: order.column, order: order.order })
    }
  }

  public setFilter(filter: Record<string, any>) {
    filter = stripEmptyKeys(filter)

    this.sessionStorage.setItem(this.filterStorageKey, JSON.stringify(filter))
    this.store.loadedPages.clear()
    this.store.canLoadNextPage$.next(true)
    this.store.page$.next(0)
    this.store.filter$.next(filter)
  }

  public resetFilter() {
    if (this.sessionStorage.getItem(this.filterStorageKey)) {
      this.sessionStorage.removeItem(this.filterStorageKey)
    }
    this.store.loadedPages.clear()
    this.store.canLoadNextPage$.next(true)
    this.store.page$.next(0)
    this.store.filter$.next(null)
  }

  public setDateRange(dateRange: DateRange | PreDefinedRange | undefined) {
    if (
      !(
        dateRange &&
        (isPreDefinedRange(dateRange) || (Array.isArray(dateRange) && dateRange.length === 2))
      )
    ) {
      this.resetDateRange()
      return
    }
    this.sessionStorage.setItem(this.dateRangeStorageKey, JSON.stringify(dateRange))
    this.store.loadedPages.clear()
    this.store.canLoadNextPage$.next(true)
    this.store.page$.next(0)
    this.store.dateRange$.next(dateRange)
  }

  public resetDateRange() {
    if (this.sessionStorage.getItem(this.dateRangeStorageKey)) {
      this.sessionStorage.removeItem(this.dateRangeStorageKey)
    }
    this.store.loadedPages.clear()
    this.store.canLoadNextPage$.next(true)
    this.store.page$.next(0)
    this.store.dateRange$.next(this.props.defaultDateRange || PreDefinedRange.Today)
  }

  public limitDisplayColumns(columns: string[]) {
    this.store.columnsDisplayLimit$.next(columns)
  }

  public resetDisplayColumns() {
    this.store.columnsDisplayLimit$.next([])
  }

  public limitExportColumns(columns: string[]) {
    this.store.columnsExportLimit$.next(columns)
  }

  public resetExportColumns() {
    this.store.columnsExportLimit$.next([])
  }

  public setSearch(search: string) {
    this.store.loadedPages.clear()
    this.store.canLoadNextPage$.next(true)
    this.store.search$.next(search)
    this.store.page$.next(0)

    if (search?.length > 0) {
      this.sessionStorage.setItem(this.searchStorageKey, search)
    } else if (this.sessionStorage.getItem(this.searchStorageKey)) {
      this.sessionStorage.removeItem(this.searchStorageKey)
    }
  }

  public clearCache() {
    if (this.sessionStorage.getItem(this.sortOrderStorageKey)) {
      this.sessionStorage.removeItem(this.sortOrderStorageKey)
    }
    if (this.sessionStorage.getItem(this.searchStorageKey)) {
      this.sessionStorage.removeItem(this.searchStorageKey)
    }
    if (this.sessionStorage.getItem(this.filterStorageKey)) {
      this.sessionStorage.removeItem(this.filterStorageKey)
    }
    if (this.sessionStorage.getItem(this.dateRangeStorageKey)) {
      this.sessionStorage.removeItem(this.dateRangeStorageKey)
    }
  }

  public select(item: Data) {
    this.store.selectedItem$.next(item)
  }

  public unselect() {
    this.store.selectedItem$.next(null)
  }

  public get isMultiSelectable() {
    return this.props.isMultiSelectable ?? false
  }

  public get keyColumn() {
    return this.props.keyColumn ?? 'id'
  }

  public get multiSelectedItems() {
    return this.store.multiSelectedItems$.value
  }

  public multiSelectItem(item: Data) {
    const multiSelectedItems = this.store.multiSelectedItems$.value
    multiSelectedItems.push(item)
    this.store.multiSelectedItems$.next([...multiSelectedItems])
  }

  public multiUnselectItem(item: Data) {
    let multiSelectedItems: Data[] = this.store.multiSelectedItems$.value
    multiSelectedItems = multiSelectedItems.filter(
      (selectedItem) =>
        (selectedItem as Record<string, any>)[this.keyColumn] !==
        (item as Record<string, any>)[this.keyColumn],
    )
    this.store.multiSelectedItems$.next([...multiSelectedItems])
  }

  public resetMultiSelectedItems() {
    this.store.multiSelectedItems$.next([])
  }

  public loadNextPage() {
    this.store.page$.next(this.store.page$.value + 1)
  }

  public get isSearchOrFilterApplied() {
    return !!this.store.search$.getValue() || !!this.store.filter$.getValue()
  }

  protected abstract config(): ListModelConfig

  protected loadStore(): ListModelStore<Data> {
    const store = {
      isLoading$: new BehaviorSubject<boolean>(true),
      page$: new BehaviorSubject<number>(0),
      pageSize$: new BehaviorSubject<number>(PAGE_SIZE),
      canLoadNextPage$: new BehaviorSubject<boolean>(true),
      search$: new BehaviorSubject<string>(''),
      orderBy$: new BehaviorSubject<OrderByData | null>(null),
      filter$: new BehaviorSubject<Record<string, any> | null>(null),
      filterOptions$: new BehaviorSubject<FilterOptions[] | undefined>(undefined),
      dateRange$: new BehaviorSubject<DateRange | PreDefinedRange | undefined>(undefined),
      defaultSortOrder$: new BehaviorSubject<DataGridSortOrder>({
        column: this.props.defaultOrderColumn ?? 'name',
        order: this.props.defaultOrderDirection ?? 'ASC',
      }),
      columns$: new BehaviorSubject<DataGridColumn[]>([]),
      columnsDisplayLimit$: new BehaviorSubject<string[]>([]),
      columnsExportLimit$: new BehaviorSubject<string[]>([]),
      gridSortOrder$: new BehaviorSubject<DataGridSortOrder | undefined>(undefined),
      // DATA
      count$: new BehaviorSubject<number>(0),
      loadedPages: new Map<number, Data[]>(),
      selectedItem$: new BehaviorSubject<Data | null>(null),
      multiSelectedItems$: new BehaviorSubject<Data[]>([]),
    }
    const onDestroy$$ = this.destroy$.subscribe(() => {
      store.isLoading$.complete()
      store.page$.complete()
      store.pageSize$.complete()
      store.canLoadNextPage$.complete()
      store.search$.complete()
      store.orderBy$.complete()
      store.gridSortOrder$.complete()
      store.filter$.complete()
      store.dateRange$.complete()
      store.count$.complete()
      store.selectedItem$.complete()
      store.multiSelectedItems$.complete()

      onDestroy$$.unsubscribe()
    })

    return store
  }

  protected loadState(): State {
    this.loadCaches()

    return {
      page$: this.store.page$.pipe(takeUntil(this.destroy$)),
      pageSize$: this.store.pageSize$.pipe(takeUntil(this.destroy$)),
      orderBy$: this.store.orderBy$.pipe(takeUntil(this.destroy$)),
      gridSortOrder$: this.store.gridSortOrder$.pipe(takeUntil(this.destroy$)),
      defaultSortOrder$: this.store.defaultSortOrder$.pipe(takeUntil(this.destroy$)),
      filter$: this.store.filter$.pipe(takeUntil(this.destroy$)),
      filterOptions$: this.createFilterOptionsStream(),
      dateRange$: this.store.dateRange$.pipe(takeUntil(this.destroy$)),
      columns$: this.createFilteredColumnsStream(),
      columnsDisplayLimit$: this.store.columnsDisplayLimit$.pipe(takeUntil(this.destroy$)),
      columnsExportLimit$: this.store.columnsExportLimit$.pipe(takeUntil(this.destroy$)),
      isLoading$: this.store.isLoading$.pipe(takeUntil(this.destroy$)),
      search$: this.store.search$.pipe(takeUntil(this.destroy$)),
      canLoadNextPage$: this.store.canLoadNextPage$.pipe(takeUntil(this.destroy$)),
      items$: this.createDataStream(),
      count$: this.createCountStream(),
      selectedItem$: this.store.selectedItem$.pipe(takeUntil(this.destroy$)),
      multiSelectedItems$: this.store.multiSelectedItems$.pipe(takeUntil(this.destroy$)),
    } as State
  }

  protected createFilteredColumnsStream() {
    return this.store.columnsDisplayLimit$.pipe(
      takeUntil(this.destroy$),
      map((limitFields) => {
        if (!limitFields || limitFields?.length < 1) {
          return this.props.gridColumns
        }
        return this.props.gridColumns.filter((column: DataGridColumn) =>
          limitFields.includes(column.field),
        )
      }),
    )
  }

  protected createFilterOptionsStream() {
    if (this.props.loadFilterOptions) {
      const result = this.props.loadFilterOptions()
      if (result instanceof Promise) {
        result.then((options) => this.store.filterOptions$.next(options))
      } else {
        this.store.filterOptions$.next(result)
      }
    }

    return this.store.filterOptions$.pipe(takeUntil(this.destroy$))
  }

  public checkNeedToLoadCache() {
    const allowedUrls = this.props.retainCacheUrl ?? []
    if (!allowedUrls?.length) {
      return false
    }
    const previousUrl = this.routerNavigationHistoryProvider.previousUrl$.value ?? ''
    if (!previousUrl?.length) {
      return true
    }
    return allowedUrls.some((item) => previousUrl.startsWith(item))
  }

  protected loadCaches() {
    const needToLoadCache = this.checkNeedToLoadCache()
    if (needToLoadCache) {
      const savedSortOrder = this.sessionStorage.getItem(this.sortOrderStorageKey)
      if (savedSortOrder) {
        try {
          this.setOrder(JSON.parse(savedSortOrder))
        } catch (error) {
          console.warn(`Cannot apply saved sort order`, error)
          this.sessionStorage.removeItem(this.sortOrderStorageKey)
        }
      } else {
        this.setOrder(this.store.defaultSortOrder$.getValue())
      }

      const savedFilter = this.sessionStorage.getItem(this.filterStorageKey)
      if (!this.store.filter$.value && savedFilter) {
        try {
          this.setFilter(JSON.parse(savedFilter))
        } catch (error) {
          console.warn(`Cannot apply saved filter`, error)
          this.sessionStorage.removeItem(this.filterStorageKey)
        }
      }

      const savedDateRange = this.sessionStorage.getItem(this.dateRangeStorageKey)
      if (!this.store.dateRange$.value) {
        if (savedDateRange) {
          try {
            const dateRange = JSON.parse(savedDateRange)
            if (isPreDefinedRange(dateRange)) {
              this.setDateRange(dateRange)
            } else {
              this.setDateRange([new Date(dateRange[0]), new Date(dateRange[1])])
            }
          } catch (error) {
            console.warn(`Cannot apply saved date range`, error)
            this.sessionStorage.removeItem(this.dateRangeStorageKey)
            this.setDateRange(this.props.defaultDateRange || PreDefinedRange.Today)
          }
        } else {
          this.setDateRange(this.props.defaultDateRange || PreDefinedRange.Today)
        }
      }

      const savedSearch = this.sessionStorage.getItem(this.searchStorageKey)
      if (savedSearch) {
        this.setSearch(savedSearch)
      }
    } else {
      this.clearCache()
      this.setOrder(this.store.defaultSortOrder$.getValue())
      this.setDateRange(this.props.defaultDateRange || PreDefinedRange.Today)
    }
  }

  protected createDataStream(): Observable<Data[]> {
    return combineLatest([
      this.store.page$,
      this.store.pageSize$,
      this.store.orderBy$,
      this.store.filter$,
      this.store.dateRange$,
      this.store.search$,
    ]).pipe(
      takeUntil(this.destroy$),
      debounceTime(100),
      tap(() => this.store.isLoading$.next(true)),
      switchMap(async ([page, pageSize, orderBy, filter, dateRange, search]) => {
        const pagesData = await this.getPagesData({
          page,
          pageSize,
          orderBy,
          filter,
          dateRange,
          search,
        })
        // Combine all pages to a single array
        return pagesData.reduce((carry, item) => carry.concat(item), [])
      }),
      tap(() => this.store.isLoading$.next(false)),
      tap((items) => {
        if (this.env.envType !== 'production') {
          console.debug(`Items ${this.props.name}`, items)
        }
      }),
      shareReplay({ bufferSize: 1, refCount: false }),
    )
  }

  protected async getPagesData(dto: GetPagesDataDto): Promise<Data[][]> {
    const { page } = dto
    // Loading every page in parallel
    const pages = [...new Array(page + 1)].map((_, i) => i)

    return Promise.all(pages.map(async (page) => this.getPageData(page, dto)))
  }

  protected async getPageData(page: number, dto: GetPagesDataDto) {
    const { pageSize, orderBy, filter, dateRange, search } = dto

    if (!this.store.loadedPages.has(page)) {
      const pageProps = {
        page,
        pageSize,
        search,
        filter: filter ?? undefined,
        order: orderBy?.order ?? this.store.defaultSortOrder$.value.order,
        orderBy: orderBy?.column ?? this.store.defaultSortOrder$.value.column,
      }
      if (this.props.applyRequestFilters) {
        this.props.applyRequestFilters(pageProps, filter)
      }
      if (this.props.applyRequestDateRange) {
        this.props.applyRequestDateRange(pageProps, dateRange, this.props.defaultDateRange)
      } else if (this.props.dateRangeColumn) {
        applyDateRangeToFilter(
          this.props.dateRangeColumn,
          pageProps,
          dateRange,
          this.props.defaultDateRange,
        )
      }
      const pageData = (await this.loadPage(pageProps)) ?? []

      // Prevent loading next pages when end is reached
      if (pageData?.length && pageData?.length < pageSize) {
        this.store.canLoadNextPage$.next(false)
      }

      this.store.loadedPages.set(page, pageData)
    }
    return this.store.loadedPages.get(page) ?? []
  }

  protected abstract loadPage(props: DataQueryProps): Promise<Data[]>

  protected reloadCount() {
    if (this.props.loadCount) {
      const result = this.props.loadCount()
      if (result instanceof Promise) {
        result.then((count) => this.store.count$.next(count))
      } else {
        this.store.count$.next(result)
      }
    }
  }

  protected createCountStream(): Observable<number> {
    this.reloadCount()
    return this.store.count$.pipe(takeUntil(this.destroy$))
  }
}

export const getDateRaneForFilter = (
  dateRange?: DateRange | PreDefinedRange,
  defaultRange?: PreDefinedRange,
) => {
  let fromDate: Date | null = null
  let toDate: Date | null = null

  if (dateRange) {
    if (isPreDefinedRange(dateRange)) {
      const range = getDateRangeFromPredefined(dateRange as PreDefinedRange) as DateRange
      fromDate = range[0]
      toDate = range[1]
    } else {
      fromDate = (dateRange as DateRange)?.[0]
      toDate = (dateRange as DateRange)?.[1]
    }
  }

  if (!fromDate || !toDate) {
    const range = getDateRangeFromPredefined(defaultRange || PreDefinedRange.Today) as DateRange
    fromDate = range[0]
    toDate = range[1]
  }
  return [fromDate, toDate]
}

export const applyDateRangeToFilter = (
  fieldName: string,
  queryProps: DataQueryProps,
  dateRange?: DateRange | PreDefinedRange,
  defaultRange?: PreDefinedRange,
) => {
  const range = getDateRaneForFilter(dateRange, defaultRange)
  const filters = queryProps?.filter ?? {}
  filters[fieldName] = { gte: range[0], lte: range[1] }
  queryProps.filter = filters
}
