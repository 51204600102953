import { Map as MaplibreGlMap, Marker } from 'maplibre-gl'
import { BaseMarker } from '@ti-platform/web/map/markers/base'
import { ComponentFactory, Coordinates, toLngLat } from '@ti-platform/web/map'

export class MaplibreGeofenceLabelMarker extends BaseMarker {
  protected nativeRef: Marker

  public constructor(
    public override readonly options: { latLng: Coordinates; label: string },
    protected override readonly mapRef: MaplibreGlMap,
    protected override readonly componentFactory: ComponentFactory<any>,
  ) {
    super(options, mapRef, componentFactory)

    this.nativeRef = new Marker({
      element: document.createElement('div'),
    }).setLngLat(toLngLat(this.options.latLng))

    // Disable cursor interaction for marker
    this.nativeRef.getElement().style.pointerEvents = 'none'

    this.nativeRef.getElement().innerHTML = `
      <div class="geofence-label text-12 fw-600">
        ${this.options.label}
      </div>
    `

    this.nativeRef.addTo(this.mapRef)
    this.destroy$.subscribe(() => this.nativeRef.remove())
  }
}
