import {
  DataQueryProps,
  Fleet,
  FleetDisplayData,
  FleetFormRequest,
  FleetsDisplayList,
  FleetView,
} from '@ti-platform/contracts'
import { stripEmptyKeys } from '@ti-platform/web/common'
import { ApiRoute } from '../../../services/api.route'

export class FleetRoute extends ApiRoute {
  public override init() {
    this.route = 'tsp/fleet'
  }

  public one(id: number) {
    return this.get<FleetView>().endpoint(`${id}`)
  }

  public list(params: DataQueryProps) {
    return this.get<FleetView[]>()
      .withParams(
        stripEmptyKeys({
          ...params,
          filter: params.filter ? JSON.stringify(params.filter) : '',
        }),
      )
      .endpoint('list')
  }

  public count() {
    return this.get<number>().endpoint('count-all')
  }

  public search(term: string, noDemo = false) {
    return this.get<FleetDisplayData[]>().withParams({ term, noDemo }).endpoint('search')
  }

  public shortDisplayList(noDemo = false) {
    // const params = noDemo ? { noDemo } : {}
    return this.get<FleetsDisplayList>().withParams({ noDemo }).endpoint('short-display-list')
  }

  public isDemoLimitReached() {
    return this.get<boolean>().endpoint('validate-demo-limit-reached')
  }

  public launch(dto: FleetFormRequest) {
    return this.post<Fleet>().withData(dto).endpoint('launch')
  }

  public update(id: number, dto: FleetFormRequest) {
    return this.post<Fleet>().withData(dto).endpoint(`update/${id}`)
  }

  public activate(id: number) {
    return this.put<Fleet>().endpoint(`activate/${id}`)
  }

  public deactivate(id: number) {
    return this.put<Fleet>().endpoint(`deactivate/${id}`)
  }

  public deleteFleet(id: number) {
    return this.delete<Fleet>().endpoint(`delete/${id}`)
  }
}
