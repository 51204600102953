<div class="tags-wrapper" #tagsWrapper>
  <p-tag
    class="{{ selectedOption?.severity ?? '' }} clickable"
    (click)="onClick($event); statusMenu.toggle($event); opened.emit()"
  >
    <div class="container flex">
      {{ selectedOption?.label ?? '' }}
      <app-icon-chevron [width]="18" [height]="18" [direction]="'down'"></app-icon-chevron>
    </div>
  </p-tag>
</div>

<p-overlayPanel #statusMenu [appendTo]="(device.isMobile$ | async) ? tagsWrapper : 'body'">
  <div class="status-tags-container">
    @for (option of options; track value) {
      @if (option.value !== selectedOption?.value) {
        <p-tag
          class="{{ option.severity }} clickable"
          (click)="valueChanged.emit(option.value); statusMenu.hide(); $event.stopPropagation()"
        >
          {{ option.label }}
        </p-tag>
      }
    }
  </div>
</p-overlayPanel>
