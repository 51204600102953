<div class="container flex">
  <div
    class="toolbar"
    [class]="{
      invisible: !!(deviceService.isMobileBreakPoint$ | async),
      'scrollbar-gray': !!(deviceService.isMobileBreakPoint$ | async),
    }"
  >
    @for (dateRange of (dateRangeOptions$ | async) ?? []; track dateRangeOptionTrackByFn) {
      <div
        [ngClass]="{ selected: selectedRangeOption === dateRange.value }"
        class="date-option clickable text-14"
        (click)="selectPreDefinedRange(dateRange.value)"
      >
        {{ dateRange.label }}
        <app-icon-check
          [color]="'var(--color-primary-500)'"
          [width]="20"
          [height]="20"
        ></app-icon-check>
      </div>
    }
  </div>
  <div class="calendar-content">
    @if (!!(deviceService.isMobileBreakPoint$ | async)) {
      <div class="selected-period text-14">
        <app-icon-date [height]="20" [width]="20" class="icon"></app-icon-date>
        @if (rangeValue && rangeValue[0]) {
          @if (isOneDay) {
            {{ rangeValue[0] | formatDate: 'grid-date' }}
          } @else if (rangeValue[0]) {
            @if (isTheSameYear) {
              {{ rangeValue[0] | formatDate: 'month-date' }} -
              {{ rangeValue[1] | formatDate: 'grid-date' }}
            } @else {
              {{ rangeValue[0] | formatDate: 'grid-date' }} -
              {{ rangeValue[1] | formatDate: 'grid-date' }}
            }
          }
        }
      </div>
    }
    <p-calendar
      #calendar
      [inline]="true"
      [ngModel]="rangeValue"
      [selectionMode]="'range'"
      [numberOfMonths]="shownNumberOfMonths"
      [readonlyInput]="true"
      (onMonthChange)="onMonthChange($event)"
      (onSelect)="onSelected($event)"
      (ngModelChange)="onRangeUpdated($event)"
    />

    <div class="footer flex--col">
      @if (
        withTime &&
        rangeValue &&
        rangeValue[0] &&
        (deviceService.isMobileBreakPoint$ | async) === false
      ) {
        <div class="time-section flex gap-12">
          <div class="date-box text-14 fw-400">
            {{ rangeValue[0] | formatDate: 'grid-date' }}
          </div>

          <app-time-input
            [date]="$any(rangeValue[0])"
            (timeChanged)="onTimeChanged($event, 'start')"
          ></app-time-input>

          @if (rangeValue[1] || this.temporaryEndDate; as endDate) {
            <div class="separator text-14 fw-400">-</div>

            <app-time-input
              [date]="endDate"
              (timeChanged)="onTimeChanged($event, 'end')"
            ></app-time-input>

            <div class="date-box text-14 fw-400">
              {{ endDate | formatDate: 'grid-date' }}
            </div>
          }
        </div>
      }

      <div class="flex gap-12">
        <p-button [severity]="'secondary'" class="w50 h--42 text-14 fw-600" (click)="onCancel()">
          {{ 'button.cancel' | translate }}
        </p-button>
        <p-button class="w50 h--42 text-14 fw-600" (click)="onSubmit()">
          {{ 'button.apply' | translate }}
        </p-button>
      </div>
    </div>
  </div>
</div>
