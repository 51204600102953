import { Alert, Location } from '../models'

export enum AlertTriggerTypes {
  sos_alarm = 'sos_alarm',
  movement = 'movement',
  engine_idling = 'engine_idling',
  ignition = 'ignition',
  speeding = 'speeding',
  geofence_activity = 'geofence_activity',
  towing = 'towing',
}

export interface DriverInfo {
  id: string
  assign_timestamp: number
}

export interface VehicleInfo {
  id: string
  assign_timestamp: number
}

export interface AlertIntervalRequiredFields {
  // interval id
  id: number
  // calculator name
  name: string
  alert_trigger: AlertTriggerTypes
  alert_trigger_key?: number | string
  // timestamp
  begin: number
  // can be additionally to begin field when begin field may not be right to set triggered at
  alert_time?: number
  // timestamp
  end: number
  // duration of interval in seconds (end - begin)
  duration: number
  active?: boolean
  // our inner additional data
  // flespi device id
  device_id: number | null
  // TI db device id
  device_ti_id: string | null
  device_fleet_id: number | null
  device_tsp_id: number | null
  driver: DriverInfo | null
  drivers_set: DriverInfo[] | null
  vehicle: VehicleInfo | null
  vehicles_set: VehicleInfo[] | null
  // may be added in process
  additional_alert_data?: {
    min_duration?: number
    state?: boolean
    speed_limit?: number
    speed_limit_unrounded?: number
    location_data?: Location
  }
}

export type AlertIntervalType = Record<string, any> & AlertIntervalRequiredFields

export interface WebhookAlertIntervalData {
  action: 'created' | 'deactivated'
  calculator_id: number
  flespi_device_id: number
  interval: AlertIntervalType
}

// TODO: Add correct types and reduce `any` usage
// TODO: Consider move this class outside of `contracts` library
export class AlertInterval implements WebhookAlertIntervalData {
  action!: 'created' | 'deactivated'
  calculator_id!: number
  flespi_device_id!: number
  interval!: AlertIntervalType

  constructor(props: WebhookAlertIntervalData) {
    Object.assign(this, props)
  }

  get tspId(): number | null {
    return this.interval.device_tsp_id
  }

  get fleetId(): number | null {
    return this.interval.device_fleet_id
  }

  get deviceId(): string | null {
    return this.interval.device_ti_id
  }

  get flespiDeviceId(): number | null {
    return this.flespi_device_id
  }

  get vehicleId(): string | undefined {
    return this.interval.vehicle?.id
  }

  get alertTrigger(): AlertTriggerTypes {
    return this.interval.alert_trigger
  }

  get entryGeofencesIds(): number[] {
    if (this.alertTrigger !== AlertTriggerTypes.geofence_activity) {
      return []
    }
    const currentGeofencesIds = (this.interval.current_geofences || []).map(
      (value: any) => value.id,
    )
    const previousGeofencesIds = (this.interval.previous_geofences || []).map(
      (value: any) => value.id,
    )
    return currentGeofencesIds.filter((value: any) => !previousGeofencesIds.includes(value))
  }

  get entryGeofences(): Record<string, any>[] {
    if (this.alertTrigger !== AlertTriggerTypes.geofence_activity) {
      return []
    }
    const currentGeofences = this.interval.current_geofences
    return currentGeofences.filter((value: any) => this.entryGeofencesIds.includes(value.id))
  }

  get exitGeofencesIds(): number[] {
    if (this.alertTrigger !== AlertTriggerTypes.geofence_activity) {
      return []
    }
    const currentGeofencesIds = (this.interval.current_geofences || []).map(
      (value: any) => value.id,
    )
    const previousGeofencesIds = (this.interval.previous_geofences || []).map(
      (value: any) => value.id,
    )
    return previousGeofencesIds.filter((value: any) => !currentGeofencesIds.includes(value))
  }

  get exitGeofences(): Record<string, any>[] {
    if (this.alertTrigger !== AlertTriggerTypes.geofence_activity) {
      return []
    }
    const previousGeofences = this.interval.previous_geofences
    return previousGeofences.filter((value: any) => this.exitGeofencesIds.includes(value.id))
  }

  public checkConsistentWithAlertSetting(setting: Alert): boolean {
    let result = true
    const alertConfig = setting.config
    if (
      alertConfig.vehicles?.length &&
      (!this.vehicleId || !alertConfig.vehicles.includes(this.vehicleId))
    ) {
      return false
    }
    // for geofence activity case
    const checkGeofencesIds = alertConfig.state ? this.entryGeofencesIds : this.exitGeofencesIds

    switch (this.alertTrigger) {
      case AlertTriggerTypes.movement:
        if (alertConfig.state !== this.interval.moving) {
          result = false
        }
        break
      case AlertTriggerTypes.ignition:
        if (alertConfig.state !== this.interval.ignition_status) {
          result = false
        }
        break
      case AlertTriggerTypes.speeding:
        if (alertConfig.speed_limit !== this.interval.alert_trigger_key) {
          result = false
        }
        break

      case AlertTriggerTypes.geofence_activity:
        // if not geofences list -> then for all geofences
        if (alertConfig.geofences_list) {
          if (!checkGeofencesIds.length) {
            result = false
          } else {
            const geofences = checkGeofencesIds.filter((value) =>
              alertConfig.geofences_list?.some((item) => item.external_id === value),
            )
            if (!geofences.length) {
              result = false
            }
          }
        }
        break
    }

    return result
  }
}
