import { ComponentRef } from '@angular/core'
import { Subject } from 'rxjs'
import { FeatureCollection } from 'geojson'
import { GeoJSONSource, Marker } from 'maplibre-gl'
import { addTouchListener } from '@ti-platform/web/ui-kit/layout'
import { VehicleMarkerComponent } from '../components'
import { Coordinates, ComponentFactory, iVehicleMarkerOptions, VehicleStatus } from '../contracts'
import { toLngLat } from '../utils'

export abstract class AbstractVehicleMarker<T = unknown> {
  public readonly onClick$ = new Subject<void>()
  public readonly destroy$ = new Subject<void>()
  public nativeRef!: T
  public abstract readonly options: iVehicleMarkerOptions
  public abstract getLatLng(): Coordinates
  public abstract setLatLng(value: Coordinates): void
  public abstract setDirectionDeg(value: number): void
  public abstract setStatus(value: VehicleStatus, isInvalid?: boolean): void
  public abstract setMapBearing(value: number): void
  public abstract setIsLightMode(value: boolean): void
  public destroy() {
    this.destroy$.next()
    this.destroy$.complete()
  }
  protected abstract readonly componentFactory: ComponentFactory<VehicleMarkerComponent>
  protected formatName(name: string): string {
    return name.length > 20 ? `${name.slice(0, 20)}...` : name
  }
}

export class MapLibreVehicleMarker extends AbstractVehicleMarker<Marker> {
  public readonly id = this.generateMarkerId()

  public mapBearing = 0
  protected componentRef?: ComponentRef<VehicleMarkerComponent>

  public constructor(
    public readonly options: iVehicleMarkerOptions,
    protected readonly componentFactory: ComponentFactory<VehicleMarkerComponent>,
    protected readonly scheduleDataSourceUpdate: (updateCb: (ds: GeoJSONSource) => void) => void,
  ) {
    super()

    this.options.name = this.formatName(this.options.name)

    this.nativeRef = new Marker({
      element: document.createElement('div'),
    }).setLngLat(toLngLat(this.options.latLng))

    this.renderMarker()
    this.initEventListeners()
    this.updateDirectionDegValue()
    this.scheduleDataSourceUpdate((dataSource: GeoJSONSource) => {
      const collection = dataSource._data as FeatureCollection
      if (Array.isArray(collection.features)) {
        collection.features.push({
          id: this.id,
          type: 'Feature',
          properties: {
            ...this.options,
          },
          geometry: {
            type: 'Point',
            coordinates: toLngLat(this.options.latLng),
          },
        })
      }
    })
  }

  public override getLatLng(): Coordinates {
    return this.options.latLng
  }

  public override setLatLng(coords: Coordinates): void {
    if (
      Array.isArray(coords) &&
      (this.options.latLng[0] !== coords[0] || this.options.latLng[1] !== coords[1])
    ) {
      const lngLat = toLngLat(coords)
      this.options.latLng = coords
      this.nativeRef.setLngLat(lngLat)

      // Update the data source to refresh clusters
      this.scheduleDataSourceUpdate((dataSource: GeoJSONSource) => {
        const collection = dataSource._data as FeatureCollection
        if (Array.isArray(collection.features)) {
          collection.features.forEach((feature) => {
            if (feature.id == this.id) {
              feature.geometry = {
                type: 'Point',
                coordinates: lngLat,
              }
            }
          })
        }
      })
    }
  }

  public override setDirectionDeg(value: number): void {
    if (value && value !== this.options.directionDeg) {
      this.options.directionDeg = value
      this.updateDirectionDegValue()
    }
  }

  public override setStatus(status: VehicleStatus, isInvalid = false) {
    if (this.options.status !== status || this.options.statusInvalid !== isInvalid) {
      this.options.status = status
      this.options.statusInvalid = isInvalid
      this.componentRef?.setInput('status', status)
      this.componentRef?.setInput('statusInvalid', isInvalid)
    }
  }

  public override setIsLightMode(value: boolean) {
    this.options.isLightMode = value
    this.componentRef?.setInput('isLightMode', value)
  }

  public override setMapBearing(value: number) {
    this.mapBearing = value
    this.updateDirectionDegValue()
  }

  public override destroy() {
    super.destroy()
    this.nativeRef.remove()
    this.componentRef?.destroy()
    // Remove current marker from the data source
    this.scheduleDataSourceUpdate((dataSource: GeoJSONSource) => {
      const collection = dataSource._data as FeatureCollection
      if (Array.isArray(collection.features)) {
        collection.features = collection.features.filter((feature) => feature.id !== this.id)
      }
    })
  }

  protected generateMarkerId(): number {
    return Math.floor(Math.random() * 10 ** 6)
  }

  protected renderMarker() {
    if (!this.componentRef) {
      this.componentFactory(VehicleMarkerComponent, this.options).then((ref) => {
        this.nativeRef.getElement().appendChild(ref.location.nativeElement)
        this.componentRef = ref
      })
    }
  }

  protected updateDirectionDegValue() {
    if (this.componentRef) {
      const directionDeg =
        this.options.directionDeg > this.mapBearing
          ? this.options.directionDeg - this.mapBearing
          : 360 + this.options.directionDeg - this.mapBearing
      this.componentRef.setInput('directionDeg', directionDeg)
      this.componentRef.changeDetectorRef.detectChanges()
    }
  }

  protected initEventListeners() {
    const onClick = () => this.onClick$.next()
    this.nativeRef.getElement().addEventListener('click', onClick)
    const removeTouchListener = addTouchListener(this.nativeRef.getElement(), onClick)
    this.destroy$.subscribe(() => {
      this.nativeRef.getElement().removeEventListener('click', onClick)
      removeTouchListener()
    })
  }
}
