import { DateRange, PreDefinedRange } from '@ti-platform/web/common'

// get date rande from predefined value
export const getDateRangeFromPredefined = (preDefinedRange: PreDefinedRange): DateRange | null => {
  const todayBeginAnchor = new Date().setHours(0, 0, 0, 0)
  const todayEndAnchor = new Date().setHours(23, 59, 59, 999)
  const todayEnd = new Date(todayEndAnchor)
  switch (preDefinedRange) {
    case PreDefinedRange.Today: {
      const todayStart = new Date(todayBeginAnchor)
      return [todayStart, todayEnd]
    }
    case PreDefinedRange.Yesterday: {
      const yesterdayStartAnchor = todayBeginAnchor - 24 * 60 * 60 * 1000
      const yesterdayStart = new Date(yesterdayStartAnchor)
      const yesterdayEndAnchor = todayEndAnchor - 24 * 60 * 60 * 1000
      const yesterdayEnd = new Date(yesterdayEndAnchor)
      return [yesterdayStart, yesterdayEnd]
    }
    case PreDefinedRange.Last7Days: {
      const last7DaysStartAnchor = todayBeginAnchor - 6 * 24 * 60 * 60 * 1000
      const last7DaysStart = new Date(last7DaysStartAnchor)
      return [last7DaysStart, todayEnd]
    }
    case PreDefinedRange.Last30Days: {
      const last30DaysStartAnchor = todayBeginAnchor - 29 * 24 * 60 * 60 * 1000
      const last30DaysStart = new Date(last30DaysStartAnchor)
      return [last30DaysStart, todayEnd]
    }
    default: {
      return null
    }
  }
}

export const isPreDefinedRange = (value: any): boolean => {
  return Object.values(PreDefinedRange).includes(value)
}
