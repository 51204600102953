import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  inject,
  Input,
  Output,
} from '@angular/core'
import { ExportFormat, injectDestroy$, Memoize } from '@ti-platform/web/common'
import { LanguageService } from '@ti-platform/web/ui-kit/i18n'
import { MenuItem } from 'primeng/api'
import { map, Observable, takeUntil } from 'rxjs'

@Component({
  selector: 'app-export-btn',
  templateUrl: 'export-btn.component.html',
  styleUrls: ['export-btn.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ExportBtnComponent {
  protected readonly languageService = inject(LanguageService)
  protected readonly destroy$ = injectDestroy$()

  protected readonly ExportFormat = ExportFormat

  @Output() export = new EventEmitter<ExportFormat>()

  @Input() severity: 'primary' | 'secondary' = 'secondary'
  @Input() compact?: boolean

  protected exportExcel() {
    this.export.emit(ExportFormat.Excel)
  }

  protected exportCsv() {
    this.export.emit(ExportFormat.CSV)
  }

  @Memoize()
  get items$(): Observable<MenuItem[]> {
    return this.languageService
      .massTranslate$({
        exportXls: 'export.xls',
        exportCsv: 'export.csv',
      })
      .pipe(
        takeUntil(this.destroy$),
        map((labels) => {
          return [
            {
              type: ExportFormat.Excel,
              label: labels.exportXls,
              command: () => {
                this.exportExcel()
              },
            },
            {
              type: ExportFormat.CSV,
              label: labels.exportCsv,
              command: () => {
                this.exportCsv()
              },
            },
          ]
        }),
      )
  }
}
