import { DatePipe } from '@angular/common'
import { inject, Injectable } from '@angular/core'
import { DateStyle } from '@ti-platform/contracts'
import { injectDestroy$ } from '@ti-platform/web/common'
import { I18NConfigProvider } from '@ti-platform/web/ui-kit/i18n'
import { LanguageService } from './language.service'

export type DateFormatType =
  | 'short-date'
  | 'month-date'
  | 'grid-date'
  | 'grid-date-time'
  | 'grid-date-time-sec'
  | 'triggered-alert-view'

@Injectable()
export class DateFormatService {
  protected readonly datePipe = inject(DatePipe)
  protected readonly destroy$ = injectDestroy$()
  protected readonly languageService = inject(LanguageService)
  protected readonly i18nConfigProvider = inject(I18NConfigProvider)

  protected readonly shortMonthRE = /Jan|Feb|Mar|Apr|May|Jun|Jul|Aug|Sep|Oct|Nov|Dec/

  protected dateFormatOptions = {
    [DateStyle.EU]: {
      'short-date': 'dd/MM/yyyy',
      'month-date': 'MMM d',
      'grid-date': 'MMM d, YYYY',
      'grid-date-time': 'MMM d, yyyy h:mm a',
      'grid-date-time-sec': 'MMM d, yyyy h:mm:ss a',
      'triggered-alert-view': 'MMM d, h:mm:ss a',
    },
    [DateStyle.US]: {
      'short-date': 'MM/dd/yyyy',
      'month-date': 'MMM d',
      'grid-date': 'MMM d, YYYY',
      'grid-date-time': 'MMM d, yyyy h:mm a',
      'grid-date-time-sec': 'MMM d, yyyy h:mm:ss a',
      'triggered-alert-view': 'MMM d, h:mm:ss a',
    },
  } as Record<DateStyle, Record<DateFormatType, string>>

  public async formatDate(date: Date | string | number, formatType: DateFormatType) {
    if (!(date instanceof Date)) {
      date = new Date(date)
    }

    const config = await this.i18nConfigProvider.getConfig()
    const format = this.dateFormatOptions[config.dateStyle][formatType]
    const formatted = this.datePipe.transform(date, format) || ''

    // Translate short month labels
    if (format.includes('MMM')) {
      const month = date.getMonth()
      const monthName = await this.languageService.translate(`date-time.month-short.${month}`)
      return formatted.replace(this.shortMonthRE, monthName)
    }

    return formatted
  }
}
