import { ComponentRef, Type } from '@angular/core'
import { Map as MaplibreGlMap, Marker } from 'maplibre-gl'
import { BaseMarker } from './base'
import { ComponentFactory, Coordinates } from '../contracts'
import { toLngLat } from '../utils'

export abstract class BaseComponentMarker<T extends object> extends BaseMarker {
  protected constructor(
    public override readonly options: unknown,
    protected override readonly mapRef: unknown,
    protected override readonly componentFactory: ComponentFactory<T>,
    protected readonly componentType: Type<T>,
  ) {
    super(options, mapRef, componentFactory)
    Promise.resolve().then(() => this.init())
  }

  protected abstract init(): unknown
}

export class MaplibreComponentMarker<T extends object> extends BaseComponentMarker<T> {
  public nativeRef!: Marker
  public componentRef?: ComponentRef<T>

  public constructor(
    public override readonly options: { latLng: Coordinates } & Partial<T>,
    protected override readonly mapRef: MaplibreGlMap,
    protected override readonly componentFactory: ComponentFactory<any>,
    protected override readonly componentType: Type<T>,
  ) {
    super(options, mapRef, componentFactory, componentType)
  }

  protected override init() {
    this.nativeRef = new Marker({
      element: document.createElement('div'),
    }).setLngLat(toLngLat(this.options.latLng))

    this.componentFactory(this.componentType, this.options).then((ref) => {
      this.nativeRef.getElement().appendChild(ref.location.nativeElement)
      this.nativeRef.addTo(this.mapRef)
      this.componentRef = ref
    })
  }

  public override destroy() {
    super.destroy()
    this.nativeRef?.remove()
    this.componentRef?.destroy()
  }
}
