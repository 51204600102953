import { inject, Injectable } from '@angular/core'
import { difference, intersection } from 'lodash'
import {
  BehaviorSubject,
  combineLatest,
  debounceTime,
  firstValueFrom,
  Subject,
  takeUntil,
  throttleTime,
  withLatestFrom,
} from 'rxjs'
import getCenter from '@turf/center'
import { GeoJSONSource, MapMouseEvent, MapTouchEvent, Marker } from 'maplibre-gl'
import { injectDestroy$ } from '@ti-platform/web/common'
import { MapAdapter, MapLibreMapAdapter, TilesSource } from '../adapters'
import {
  Coordinates,
  GeofenceFeature,
  MapGeofenceDataSource,
  MapGeofenceVisualizer,
} from '../contracts'
import { MaplibreGeofenceLabelMarker } from '../markers'
import { addMaplibreLongTouchListener, addMaplibreSingleTouchListener, toLatLng } from '../utils'

@Injectable()
export class MaplibreGeofenceVisualizer extends MapGeofenceVisualizer {
  protected readonly adapter = inject(MapAdapter) as MapLibreMapAdapter
  protected readonly dataSource = inject(MapGeofenceDataSource)
  protected readonly destroy$ = injectDestroy$()

  public readonly isEnabled$ = new BehaviorSubject<boolean>(true)
  public readonly enablePinMarkers$ = new BehaviorSubject<boolean>(false)
  public readonly hiddenGeofenceIds$ = new BehaviorSubject<string[]>([])
  public readonly refreshDataSource$ = new Subject<void>()

  public readonly SOURCE_ID = 'GEOFENCE_SOURCE'
  public readonly FILL_LAYER_ID = 'geofence-fill'
  public readonly STROKE_LAYER_ID = 'geofence-stroke'

  protected readonly GEOFENCE_DARK_COLOR = '#7F56D9'
  protected readonly GEOFENCE_LIGHT_COLOR = '#B692F6'

  public constructor() {
    super()
    this.adapter.onInit$.subscribe(() => this.init())
  }

  public get geofenceColor() {
    return this.adapter.tilesSource$.value !== TilesSource.GoogleSatellite
      ? this.GEOFENCE_DARK_COLOR
      : this.GEOFENCE_LIGHT_COLOR
  }

  public fitMapToViewAll(padding?: number) {
    firstValueFrom(this.dataSource.data$).then((geofences) => {
      if (geofences.length) {
        const boundaries: Coordinates[] = geofences.reduce((carry, item) => {
          return carry.concat((item.geometry.coordinates[0] as Coordinates[]).map(toLatLng))
        }, new Array<Coordinates>())
        this.adapter.fitBounds(boundaries, 20, 20, padding)
      }
    })
  }

  protected init() {
    this.initSource()
    this.initLayers()
    this.initDataSourceSync()
    this.initGeofencePinMarkers()
    this.initGeofenceTapListener()
    this.initGeofenceClickListener()
    this.initGeofenceLabelsOnHover()

    // Reinitialize the layers after style data is updated
    const repairAfterStyleRefresh = () => {
      this.initSource()
      this.initLayers()
      this.refreshDataSource$.next()
    }
    this.adapter.map.on('styledata', repairAfterStyleRefresh)
    this.destroy$.subscribe(() => this.adapter.map.off('styledata', repairAfterStyleRefresh))
  }

  protected initSource() {
    if (!this.adapter.map.getSource(this.SOURCE_ID)) {
      this.adapter.map.addSource(this.SOURCE_ID, {
        type: 'geojson',
        data: { type: 'FeatureCollection', features: [] },
      })
    }
  }

  protected initLayers() {
    if (!this.adapter.map.getLayer(this.FILL_LAYER_ID)) {
      this.adapter.map.addLayer({
        id: this.FILL_LAYER_ID,
        type: 'fill',
        source: this.SOURCE_ID,
        paint: {
          'fill-color': this.geofenceColor,
          'fill-outline-color': this.geofenceColor,
          'fill-opacity': 0.16,
        },
      })
    }

    if (!this.adapter.map.getLayer(this.STROKE_LAYER_ID)) {
      this.adapter.map.addLayer({
        id: this.STROKE_LAYER_ID,
        type: 'line',
        source: this.SOURCE_ID,
        layout: {
          'line-join': 'round',
          'line-cap': 'round',
        },
        paint: {
          'line-color': this.geofenceColor,
          'line-width': 3,
        },
      })
    }
  }

  protected initDataSourceSync() {
    // Automatically update data source
    combineLatest([
      this.isEnabled$,
      this.hiddenGeofenceIds$,
      this.dataSource.data$,
      this.refreshDataSource$,
    ])
      .pipe(debounceTime(25), takeUntil(this.destroy$))
      .subscribe(([isEnabled, hiddenIds, allGeofences]) => {
        const displayedGeofences = isEnabled
          ? allGeofences.filter((geofence) => !hiddenIds.includes(geofence.properties.id))
          : []
        const dataSource = this.adapter.map.getSource(this.SOURCE_ID) as GeoJSONSource
        if (dataSource) {
          dataSource.setData({ type: 'FeatureCollection', features: displayedGeofences })
        }
      })
  }

  protected initGeofencePinMarkers() {
    const markersMap = new Map<string, Marker>()
    combineLatest([this.enablePinMarkers$, this.dataSource.data$])
      .pipe(debounceTime(125), takeUntil(this.destroy$))
      .subscribe(([enabled, geofences]) => {
        if (!enabled) {
          geofences = []
        }

        // Create new geofences
        geofences.forEach((geofence) => {
          if (!markersMap.has(geofence.properties.id)) {
            const marker = new Marker({
              element: document.createElement('div'),
            })

            marker.getElement().innerHTML = pinMarkerSVG
            marker.getElement().style.pointerEvents = 'none'

            const coords =
              geofence.properties.center ??
              (getCenter(geofence).geometry.coordinates as Coordinates)

            marker.setLngLat(coords)
            marker.addTo(this.adapter.map)
            markersMap.set(geofence.properties.id, marker)
          }
        })

        // Delete unnecessary geofences
        const geofenceIds = geofences.map((geofence) => geofence.properties.id)
        Array.from(markersMap.entries()).forEach(([id, marker]) => {
          if (!geofenceIds.includes(id)) {
            marker.remove()
            markersMap.delete(id)
          }
        })
      })

    this.destroy$.subscribe(() => {
      markersMap.forEach((marker) => marker.remove())
      markersMap.clear()
    })
  }

  protected initGeofenceTapListener() {
    const listener = (e: MapMouseEvent | MapTouchEvent) => {
      const features = this.adapter.map.queryRenderedFeatures(e.point, {
        layers: [this.FILL_LAYER_ID],
      }) as unknown as GeofenceFeature[]

      if (features.length && features[0].properties.id) {
        this.onTap$.next(features[0].properties.id)
      }
    }

    const offTapListener = addMaplibreSingleTouchListener(
      this.adapter.map,
      this.FILL_LAYER_ID,
      listener,
    )

    this.destroy$.subscribe(() => offTapListener())
  }

  protected initGeofenceClickListener() {
    const listener = (e: MapMouseEvent | MapTouchEvent) => {
      if (this.adapter.map.doubleClickZoom.isEnabled()) {
        this.adapter.map.doubleClickZoom.disable()
      }

      const features = this.adapter.map.queryRenderedFeatures(e.point, {
        layers: [this.FILL_LAYER_ID],
      }) as unknown as GeofenceFeature[]

      if (features.length && features[0].properties.id) {
        this.onDblClick$.next(features[0].properties.id)
      }
    }

    const offLongTouchListener = addMaplibreLongTouchListener(
      this.adapter.map,
      this.FILL_LAYER_ID,
      listener,
    )

    this.adapter.map.on('dblclick', this.FILL_LAYER_ID, listener)
    this.destroy$.subscribe(() => {
      offLongTouchListener()
      this.adapter.map.off('dblclick', this.FILL_LAYER_ID, listener)
    })
  }

  protected initGeofenceLabelsOnHover() {
    const featureMarkersMap = new Map<string, MaplibreGeofenceLabelMarker>()
    const checkGeofenceHover$ = new Subject<MapMouseEvent | MapTouchEvent>()
    checkGeofenceHover$
      .pipe(
        takeUntil(this.destroy$),
        throttleTime(500, undefined, { leading: true, trailing: true }),
        withLatestFrom(this.dataSource.data$),
      )
      .subscribe(([e, allFeatures]) => {
        const hoveredFeatures = this.adapter.map.queryRenderedFeatures(e.point, {
          layers: [this.FILL_LAYER_ID],
        }) as unknown as GeofenceFeature[]

        const markerFeatureIds = Array.from(featureMarkersMap.keys())
        const hoveredFeatureIds = hoveredFeatures.map((feature) => feature.properties.id)

        const featureIdsToKeep = intersection(markerFeatureIds, hoveredFeatureIds)
        const featureIdsToRender = difference(hoveredFeatureIds, featureIdsToKeep)
        const featureIdsToDelete = difference(markerFeatureIds, featureIdsToKeep)

        if (featureIdsToRender.length) {
          featureIdsToRender
            .map((featureId) => allFeatures.find((f) => f.properties.id === featureId))
            .filter(Boolean)
            .forEach((feature) => {
              feature = feature as GeofenceFeature
              const coordinates = feature.properties.isCircle
                ? feature.properties.center
                : (getCenter(feature.geometry).geometry.coordinates as Coordinates)

              if (coordinates) {
                featureMarkersMap.set(
                  feature.properties.id,
                  new MaplibreGeofenceLabelMarker(
                    {
                      label: feature.properties.name || '',
                      latLng: toLatLng(coordinates),
                    },
                    this.adapter.map,
                    this.adapter.getComponentFactory(),
                  ),
                )
              }
            })
        }

        if (featureIdsToDelete.length) {
          featureIdsToDelete.forEach((featureId) => {
            const marker = featureMarkersMap.get(featureId)
            if (marker) {
              marker.destroy()
              featureMarkersMap.delete(featureId)
            }
          })
        }
      })

    const mouseListener = (e: MapMouseEvent | MapTouchEvent) => checkGeofenceHover$.next(e)
    this.adapter.map.on('mouseenter', this.FILL_LAYER_ID, mouseListener)
    this.adapter.map.on('mouseleave', this.FILL_LAYER_ID, mouseListener)
    this.adapter.map.on('mousemove', this.FILL_LAYER_ID, mouseListener)
    this.adapter.map.on('touchstart', this.FILL_LAYER_ID, mouseListener)
    this.adapter.map.on('touchend', mouseListener)

    this.destroy$.subscribe(() => {
      this.adapter.map.off('mouseenter', this.FILL_LAYER_ID, mouseListener)
      this.adapter.map.off('mouseleave', this.FILL_LAYER_ID, mouseListener)
      this.adapter.map.off('mousemove', this.FILL_LAYER_ID, mouseListener)
      this.adapter.map.off('touchstart', this.FILL_LAYER_ID, mouseListener)
      this.adapter.map.off('touchend', mouseListener)
    })
  }
}

const pinMarkerSVG = `<svg width="52" height="53" viewBox="0 0 52 53" fill="none" xmlns="http://www.w3.org/2000/svg">
<rect x="2" y="2.5" width="48" height="48" rx="24" fill="#7F56D9" style="fill:#7F56D9;fill:color(display-p3 0.4964 0.3384 0.8498);fill-opacity:1;"/>
<rect x="2" y="2.5" width="48" height="48" rx="24" stroke="#F4EBFF" style="stroke:#F4EBFF;stroke:color(display-p3 0.9569 0.9216 1.0000);stroke-opacity:1;" stroke-width="4"/>
<path d="M26 36.5C26 36.5 34 31.0455 34 24.6818C34 22.5119 33.1571 20.4308 31.6569 18.8964C30.1566 17.362 28.1217 16.5 26 16.5C23.8783 16.5 21.8434 17.362 20.3431 18.8964C18.8429 20.4308 18 22.5119 18 24.6818C18 31.0455 26 36.5 26 36.5Z" fill="white" stroke="white" style="fill:white;fill-opacity:1;stroke:white;stroke-opacity:1;" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M26 27.5C27.6569 27.5 29 26.1569 29 24.5C29 22.8431 27.6569 21.5 26 21.5C24.3431 21.5 23 22.8431 23 24.5C23 26.1569 24.3431 27.5 26 27.5Z" fill="#7F56D9" style="fill:#7F56D9;fill:color(display-p3 0.4964 0.3384 0.8498);fill-opacity:1;"/>
</svg>
`
