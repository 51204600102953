<div
  class="round-icon-marker-wrapper"
  [ngStyle]="{
    '--icon-color': color,
  }"
>
  <div class="shadow"></div>

  <div class="marker-icon">
    <app-transport-icon-selector
      [color]="'var(--color-white)'"
      [icon]="icon"
    ></app-transport-icon-selector>
  </div>
</div>
