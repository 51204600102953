import { ApiRoute } from '../../services/api.route'
import { MyProfile } from '@ti-platform/contracts'

export class MyProfileRoute extends ApiRoute {
  public override init() {
    this.route = 'me'
  }

  public getData() {
    return this.get<MyProfile>().endpoint('')
  }

  public isActive() {
    return this.get<boolean>().endpoint('is-active')
  }
}
