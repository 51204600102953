import { Channel } from './channel'
import { Fleet } from './fleet'
import { UserProfile } from './user-profile'
import { Vehicle } from './vehicle'

export interface Device {
  id: string
  name: string
  model: string
  isVideo?: boolean
  videoChannelsNumber?: number
  identifier: string
  config: DeviceConfig
  sensorsConfig: DeviceSensorsConfig
  sim: string
  status: DeviceStatus
  createdAt: Date
  fleetId: number
  tspId: number
  externalId: number
  externalDeviceTypeId: number
  channelId: number
  createdBy: string
  channel?: Channel
  fleet?: Fleet
  vehicle?: Vehicle
  creator?: UserProfile
}

export enum DeviceStatus {
  Unassigned,
  Inactive,
  Active,
  Deleted,
}

export interface DeviceConfig {
  ident?: string
  phone?: string
  password?: string
  settings_polling?: string
  cameras?: Array<{ name: string; channel: number }>
  ftp_password?: string
}

export enum OdometerSourceTypes {
  telemetry = 'telemetry',
  calculator = 'calculator',
}

export interface DeviceSensorsConfig {
  odometer_source_type?: OdometerSourceTypes
  odometer_parameter?: string
  battery_parameter?: string
  ignition_parameter?: string
  // km
  initial_mileage?: number
}
