import { getClient, SDK_VERSION } from '@sentry/core';
import { WINDOW } from '../helpers.js';

// This is a map of integration function method to bundle file name.
const LazyLoadableIntegrations = {
  replayIntegration: 'replay',
  replayCanvasIntegration: 'replay-canvas',
  feedbackIntegration: 'feedback',
  feedbackModalIntegration: 'feedback-modal',
  feedbackScreenshotIntegration: 'feedback-screenshot',
  captureConsoleIntegration: 'captureconsole',
  contextLinesIntegration: 'contextlines',
  linkedErrorsIntegration: 'linkederrors',
  debugIntegration: 'debug',
  dedupeIntegration: 'dedupe',
  extraErrorDataIntegration: 'extraerrordata',
  httpClientIntegration: 'httpclient',
  reportingObserverIntegration: 'reportingobserver',
  rewriteFramesIntegration: 'rewriteframes',
  sessionTimingIntegration: 'sessiontiming',
  browserProfilingIntegration: 'browserprofiling'
};
const WindowWithMaybeIntegration = WINDOW;

/**
 * Lazy load an integration from the CDN.
 * Rejects if the integration cannot be loaded.
 */
async function lazyLoadIntegration(name) {
  const bundle = LazyLoadableIntegrations[name];

  // `window.Sentry` is only set when using a CDN bundle, but this method can also be used via the NPM package
  const sentryOnWindow = WindowWithMaybeIntegration.Sentry = WindowWithMaybeIntegration.Sentry || {};
  if (!bundle) {
    throw new Error(`Cannot lazy load integration: ${name}`);
  }

  // Bail if the integration already exists
  const existing = sentryOnWindow[name];
  if (typeof existing === 'function') {
    return existing;
  }
  const url = getScriptURL(bundle);
  const script = WINDOW.document.createElement('script');
  script.src = url;
  script.crossOrigin = 'anonymous';
  script.referrerPolicy = 'origin';
  const waitForLoad = new Promise((resolve, reject) => {
    script.addEventListener('load', () => resolve());
    script.addEventListener('error', reject);
  });
  WINDOW.document.body.appendChild(script);
  try {
    await waitForLoad;
  } catch (e) {
    throw new Error(`Error when loading integration: ${name}`);
  }
  const integrationFn = sentryOnWindow[name];
  if (typeof integrationFn !== 'function') {
    throw new Error(`Could not load integration: ${name}`);
  }
  return integrationFn;
}
function getScriptURL(bundle) {
  const client = getClient();
  const options = client && client.getOptions();
  const baseURL = options && options.cdnBaseUrl || 'https://browser.sentry-cdn.com';
  return new URL(`/${SDK_VERSION}/${bundle}.min.js`, baseURL).toString();
}
export { lazyLoadIntegration };
