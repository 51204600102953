import { ApiContext } from '../../contracts'
import { AlertRoute } from './routes/alert.route'
import { LocationsRoute } from './routes/locations.route'
import { ReportsRoute } from './routes/reports.route'
import { SettingsRoute } from './routes/settings.route'
import { TriggeredAlertRoute } from './routes/triggered-alert.route'
import { UserRoute } from './routes/user.route'
import { VehiclesRoute } from './routes/vehicles.route'
import { WhiteLabelRoute } from './routes/white-label.route'

export class FleetScope {
  public readonly alert!: AlertRoute
  public readonly locations!: LocationsRoute
  public readonly reports!: ReportsRoute
  public readonly settings: SettingsRoute
  public readonly triggeredAlert!: TriggeredAlertRoute
  public readonly user!: UserRoute
  public readonly vehicle!: VehiclesRoute
  public readonly whiteLabel!: WhiteLabelRoute

  public constructor(context: ApiContext) {
    this.alert = new AlertRoute(context)
    this.locations = new LocationsRoute(context)
    this.reports = new ReportsRoute(context)
    this.settings = new SettingsRoute(context)
    this.triggeredAlert = new TriggeredAlertRoute(context)
    this.user = new UserRoute(context)
    this.vehicle = new VehiclesRoute(context)
    this.whiteLabel = new WhiteLabelRoute(context)
  }
}
